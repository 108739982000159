import React from 'react';
import './css/ErrorModal.css'; // Asegúrate de tener los estilos

const ErrorModal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Ticket No Encontrado</h2> {/* Cambiamos el título */}
        <p>{message}</p> {/* Mostramos el mensaje que pasamos como propiedad */}
        <button onClick={onClose} className="close-modal-button">Cerrar</button>
      </div>
    </div>
  );
};

export default ErrorModal;
