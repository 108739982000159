import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './css/AutoResponseForm.css';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importar íconos

const API_URL = process.env.REACT_APP_API_URL;

const AutoResponseForm = () => {
  const { id } = useParams();
  const [context, setContext] = useState('');
  const [message, setMessage] = useState('');
  const [language, setLanguage] = useState('es');
  const [startTime, setStartTime] = useState(''); // Horario de inicio
  const [endTime, setEndTime] = useState('');     // Horario de fin
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchAutoResponse = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${API_URL}/api/auto-responses/${id}`, { withCredentials: true });
          setContext(response.data.context);
          setMessage(response.data.message);
          setLanguage(response.data.language);
          setStartTime(response.data.startTime || ''); // Establecer el horario de inicio si existe
          setEndTime(response.data.endTime || '');     // Establecer el horario de fin si existe
          setLoading(false);
        } catch (error) {
          console.error('Error al obtener la respuesta automática:', error);
          setError('Error al cargar la respuesta automática.');
          setLoading(false);
        }
      };
      fetchAutoResponse();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!context || !message || !language || (context === 'businessHours' && (!startTime || !endTime))) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    try {
      if (id) {
        await axios.put(
          `${API_URL}/api/auto-responses/${id}`,
          { context, message, language, startTime, endTime },
          { withCredentials: true }
        );
        setSuccessMessage('Respuesta automática actualizada con éxito');
      } else {
        await axios.post(
          `${API_URL}/api/auto-responses`,
          { context, message, language, startTime, endTime },
          { withCredentials: true }
        );
        setSuccessMessage('Respuesta automática creada con éxito');
      }

      setError(null);
      setTimeout(() => {
        navigate('/admin/auto-responses');
      }, 2000);
    } catch (error) {
      console.error('Error al guardar la respuesta automática:', error);
      setError('Error al guardar la respuesta automática.');
    }
  };

  const handleCancel = () => {
    navigate('/admin/auto-responses');
  };

  const insertVariable = (variable) => {
    const cursorPosition = document.getElementById('message').selectionStart;
    const textBefore = message.substring(0, cursorPosition);
    const textAfter = message.substring(cursorPosition, message.length);
    setMessage(`${textBefore}${variable}${textAfter}`);
  };

  if (loading) return <p>Cargando...</p>;

  return (
    <form className="auto-response-form" onSubmit={handleSubmit}>
      <h2>{id ? 'Editar Auto Respuesta' : 'Nueva Auto Respuesta'}</h2>

      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <div className="form-group">
        <label>Contexto:</label>
        <select value={context} onChange={(e) => setContext(e.target.value)} required>
          <option value="">Seleccionar contexto</option>
          <option value="noAgentsAvailable">Sin Agentes Disponibles</option>
          <option value="ticketAssigned">Ticket Asignado</option>
          <option value="businessHours">Horario de Atención</option>
          <option value="allAgentsBusy">Todos los Agentes Ocupados</option>
          <option value="ticketPendingQueueUpdate">Actualización de Cola de Ticket Pendiente</option> {/* Nuevo contexto */}
          <option value="agentBecameAvailable">Agente Disponible</option> {/* Nuevo contexto */}
          <option value="ticketClosed">Ticket Cerrado</option> {/* Nuevo contexto */}
        </select>
      </div>

      <div className="form-group">
        <label>Mensaje:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Escribe el mensaje..."
          required
        />
      </div>

      {/* Mostrar las variables dinámicas solo si no es "Horario de Atención" */}
      {context !== 'businessHours' && (
        <div className="dynamic-variables">
          <p>Puedes usar las siguientes variables dinámicas (haz clic para insertar):</p>
          <ul>
            <li>
              <button type="button" onClick={() => insertVariable('{{ticketNumber}}')}>
                {'{{ticketNumber}}'}
              </button>: Número de ticket
            </li>
            {(context === 'ticketAssigned' || context === 'agentBecameAvailable') && ( // Mostrar variable de nombre del agente solo para contextos relevantes
              <li>
                <button type="button" onClick={() => insertVariable('{{agentName}}')}>
                  {'{{agentName}}'}
                </button>: Nombre del agente
              </li>
            )}
            {(context === 'allAgentsBusy' || context === 'ticketPendingQueueUpdate') && ( // Mostrar variable de posición en la cola para los contextos
              <li>
                <button type="button" onClick={() => insertVariable('{{queuePosition}}')}>
                  {'{{queuePosition}}'}
                </button>: Posición en la cola
              </li>
            )}
          </ul>
        </div>
      )}

      {/* Mostrar los horarios solo para "Horario de Atención" */}
      {context === 'businessHours' && (
        <>
          <div className="form-group">
            <label>Horario de Inicio:</label>
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required={context === 'businessHours'} // Obligatorio solo si es "Horario de Atención"
            />
          </div>

          <div className="form-group">
            <label>Horario de Fin:</label>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required={context === 'businessHours'} // Obligatorio solo si es "Horario de Atención"
            />
          </div>
        </>
      )}

      <div className="form-group">
        <label>Idioma:</label>
        <select value={language} onChange={(e) => setLanguage(e.target.value)} required>
          <option value="es">Español</option>
          <option value="en">Inglés</option>
        </select>
      </div>

      <div className="form-actions">
        <button type="submit" className="btn btn-success">
          <FaCheck /> {id ? 'Actualizar' : 'Crear'}
        </button>
        <button type="button" className="btn btn-danger" onClick={handleCancel}>
          <FaTimes /> Cancelar
        </button>
      </div>
    </form>
  );
};

export default AutoResponseForm;
