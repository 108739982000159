import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Para las alertas
import styles from './css/UserForm.module.css'; // Estilos para el formulario

const UserForm = ({ fetchUsers, editingUser, setEditingUser }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('agent');
  const [password, setPassword] = useState(''); // Añadimos el campo de contraseña
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false); // Para el spinner de carga

  // Validar formulario antes de enviar
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'El formato del email no es válido.',
      });
      return false;
    }

    // Validar contraseña solo si se está creando o si se ingresó una nueva contraseña
    if (password && password.length < 6) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'La contraseña debe tener al menos 6 caracteres.',
      });
      return false;
    }

    return true;
  };

  // Este efecto inicializa el formulario con los datos del usuario que se está editando o lo limpia si es un nuevo usuario
  useEffect(() => {
    if (editingUser) {
      setName(editingUser.name);
      setEmail(editingUser.email);
      setRole(editingUser.role);
      setPassword(''); // No queremos mostrar la contraseña actual en el campo
      setIsEditing(true);
    } else {
      clearForm();
    }
  }, [editingUser]);

  const clearForm = () => {
    setName('');
    setEmail('');
    setRole('agent');
    setPassword('');
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar antes de enviar
    if (!validateForm()) return;

    setLoading(true); // Mostrar spinner de carga
    try {
      if (isEditing) {
        // Actualizar usuario existente
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/users/${editingUser._id}`,
          {
            name,
            email,
            role,
            password: password !== '' ? password : undefined, // Solo actualizar si la contraseña está presente
          },
          { withCredentials: true }
        );
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Usuario actualizado correctamente.',
        });
      } else {
        // Crear nuevo usuario
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/register`,  // Aquí va la ruta del authController
          {
            name,
            email,
            role,
            password,
          },
          { withCredentials: true }
        );
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Usuario creado correctamente.',
        });
      }

      fetchUsers(); // Actualizar la lista de usuarios en el componente padre
      clearForm();
      setEditingUser(null); // Cerrar el modal o el formulario después de la acción
    } catch (error) {
      console.error('Error al guardar usuario:', error);

      // Obtener un mensaje específico de error de la respuesta de Axios
      let errorMessage = 'Hubo un problema al guardar el usuario.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.response && error.response.data && error.response.data.errors) {
        // Mongoose puede devolver errores más detallados
        const validationErrors = Object.values(error.response.data.errors).map(err => err.message).join(', ');
        errorMessage = validationErrors || errorMessage;
      }

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setLoading(false); // Ocultar spinner de carga
    }
  };

  return (
    <form className={styles.userForm} onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="admin">Admin</option>
        <option value="agent">Agente</option>
      </select>

      {/* Campo para la contraseña */}
      <input
        type="password"
        placeholder="Contraseña (mínimo 6 caracteres, dejar en blanco para no cambiar)"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button type="submit" className={styles.submitBtn} disabled={loading}>
        {loading ? 'Procesando...' : isEditing ? 'Actualizar Usuario' : 'Crear Usuario'}
      </button>
    </form>
  );
};

export default UserForm;
