import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function ProtectedRoute({ children, role }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/auth/check`, { withCredentials: true });
        if (response.data.role === role) {
          setIsAuthenticated(true);
        } else {
          setError('Acceso Denegado');
        }
      } catch (error) {
        console.error('Error en la autenticación:', error);
        setError('Error de autenticación. Por favor, inténtalo más tarde.');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [role]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error === 'Acceso Denegado') {
    return <Navigate to="/acceso-denegado" />;  // Redirigir a una página dedicada de Acceso Denegado
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;  // Redirigir al login si no está autenticado
  }

  return children;
}

export default ProtectedRoute;
