import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Navbar.css';
import { FaSignOutAlt, FaPowerOff, FaBars } from 'react-icons/fa';
import Avatar from 'react-avatar';  // Importamos react-avatar
import TicketModal from './TicketModal';  // Importamos el modal para mostrar detalles del ticket
import ErrorModal from './ErrorModal';  // Importamos el modal de error

const API_URL = process.env.REACT_APP_API_URL;

const Navbar = ({ agentName, agentStatus, setAgentStatus }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [ticketNumber, setTicketNumber] = useState(''); // Estado para manejar el número de ticket
  const [ticketDetails, setTicketDetails] = useState(null); // Estado para los detalles del ticket
  const [showModal, setShowModal] = useState(false); // Estado para mostrar el modal
  const [showErrorModal, setShowErrorModal] = useState(false); // Estado para mostrar el modal de error
  const navigate = useNavigate(); // Para redirigir después de cerrar sesión

  // Función para cambiar el estado del agente
  const toggleAgentStatus = async () => {
    const newStatus = agentStatus === 'online' ? 'offline' : 'online';
    try {
      await axios.patch(`${API_URL}/api/agents/status`, { status: newStatus }, { withCredentials: true });
      setAgentStatus(newStatus);
      setMenuOpen(false); // Cierra el menú después de cambiar estado
    } catch (error) {
      console.error('Error al cambiar el estado:', error);
    }
  };

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await axios.post(`${API_URL}/api/auth/logout`, {}, { withCredentials: true });
      navigate('/login'); // Redirigir al login después de cerrar sesión
      setMenuOpen(false); // Cierra el menú después de cerrar sesión
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // Abrir/cerrar menú de torta
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Función para manejar el envío de la búsqueda
  const handleSearchTicket = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_URL}/api/tickets/ticketNumber/${ticketNumber}`, { withCredentials: true });
      if (response.data && response.data.data) {
        setTicketDetails(response.data.data); // Guardar los detalles del ticket
        setShowModal(true); // Mostrar el modal
        setShowErrorModal(false); // Asegurarse de que el modal de error no esté visible
      } else {
        setShowErrorModal(true); // Mostrar el modal de error si no se encuentra el ticket
      }
      setMenuOpen(false); // Cerrar el menú después de buscar
    } catch (error) {
      console.error('Error al buscar el ticket:', error);
      setShowErrorModal(true); // Mostrar el modal de error en caso de error
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        {/* Avatar generado automáticamente */}
        <Avatar name={agentName} round={true} size="40" className="avatar" />
        
        <div className="agent-info">
          <h3>{agentName}</h3>
          <p>{`Estado: ${agentStatus}`}</p>
        </div>
      </div>

      {/* Campo de búsqueda de ticket */}
      <form onSubmit={handleSearchTicket} className="search-form">
        <input 
          type="text"
          value={ticketNumber}
          onChange={(e) => setTicketNumber(e.target.value)}
          placeholder="Número de ticket"
          className="search-input"
        />
        <button type="submit" className="search-button">Buscar</button>
      </form>

      <div className="navbar-right">
        <FaBars onClick={toggleMenu} className="menu-icon" />

        {/* Menú de torta */}
        {menuOpen && (
          <div className="dropdown-menu">
            <button onClick={toggleAgentStatus}>
              <FaPowerOff /> {agentStatus === 'online' ? 'Cambiar a Offline' : 'Cambiar a Online'}
            </button>
            <button onClick={handleLogout}>
              <FaSignOutAlt /> Cerrar Sesión
            </button>
          </div>
        )}
      </div>

      {/* Modal para mostrar los detalles del ticket */}
      {showModal && (
        <TicketModal 
          ticket={ticketDetails} 
          onClose={() => setShowModal(false)} 
        />
      )}

      {/* Modal de error si el ticket no se encuentra */}
      {showErrorModal && (
        <ErrorModal 
          message="Ticket no encontrado. Verifique el número de ticket." 
          onClose={() => setShowErrorModal(false)} 
        />
      )}
    </div>
  );
};

export default Navbar;
