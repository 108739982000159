import React, { useState, useEffect } from 'react';

const TicketItem = ({ ticket, flashTicketId, selectTicket, isSelected }) => {
  const [totalTime, setTotalTime] = useState('');
  const [assignedTime, setAssignedTime] = useState('');

  // Función para calcular el tiempo
  const calculateTime = (startTime) => {
    const now = new Date();
    const createdAt = new Date(startTime);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    // Actualizar los tiempos cada segundo
    const interval = setInterval(() => {
      setTotalTime('Tiempo total: ' + calculateTime(ticket.createdAt));

      if (ticket.assignedAt && new Date(ticket.assignedAt) !== 'Invalid Date') {
        setAssignedTime('Tiempo desde asignación: ' + calculateTime(ticket.assignedAt));
      } else {
        setAssignedTime(''); // No mostrar tiempo de asignación si no está disponible
      }
    }, 1000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, [ticket.createdAt, ticket.assignedAt]);

  const isFlashing = ticket._id === flashTicketId ? 'flashing' : '';
  const selectedClass = isSelected ? 'selected' : '';

  return (
    <div
      className={`ticket-item ${isFlashing} ${selectedClass}`} 
      onClick={() => selectTicket(ticket)}
    >
      <h4>{ticket.title}</h4>
      <p>{ticket.description}</p>
      <p className="total-time">{totalTime}</p>
      {assignedTime && <p className="assigned-time">{assignedTime}</p>}
    </div>
  );
};

export default TicketItem;
