import styled from 'styled-components';

// Estilo del contenedor principal con imagen de fondo
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('/assets/background.jpg');  // Imagen de fondo para toda la página
  background-size: cover;  // Asegura que la imagen cubra todo el fondo
  background-position: center;  // Centra la imagen
  background-repeat: no-repeat; // Evita que la imagen se repita
  position: relative;
`;

// Estilo del overlay (si aún deseas usarlo)
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  // Fondo oscuro con opacidad
  z-index: -1;
`;

// Estilo del formulario con fondo transparente o semitransparente
export const FormWrapper = styled.div`
  background-color: rgba(250, 250, 250, 0.9);  // Fondo blanco semitransparente
  padding: 50px;
  border-radius: 35px;  // Formulario redondeado
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  z-index: 1;
  position: relative;
`;

// Título del formulario
export const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;  // Azul suave
`;


// Contenedor de los inputs
export const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

// Estilo de los inputs redondeados
export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ className }) => (className === 'error' ? 'red' : '#ccc')}; // Borde rojo si hay error
  border-radius: 30px;  // Bordes redondeados en los inputs
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  
  &:focus {
    border-color: ${({ className }) => (className === 'error' ? 'red' : '#007bff')};  // Color azul en foco
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);  // Efecto de sombra en foco
  }
`;

// Botón de envío redondeado
export const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 30px;  // Botón redondeado
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

// Estilo para mostrar mensajes de error
export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
`;
