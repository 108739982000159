import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/AutoResponses.css';

const API_URL = process.env.REACT_APP_API_URL;

const AutoResponsesPage = () => {
  const [autoResponses, setAutoResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAutoResponses = async () => {
      try {
        console.log('Obteniendo respuestas automáticas del servidor...');
        const response = await axios.get(`${API_URL}/api/auto-responses`, { withCredentials: true });
        console.log('Respuestas automáticas obtenidas:', response.data);
        setAutoResponses(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener las respuestas automáticas:', error);
        setError('Error al cargar las respuestas automáticas.');
        setLoading(false);
      }
    };

    fetchAutoResponses();
  }, []);

  const handleToggleActive = async (id) => {
    try {
      console.log('Cambiando el estado para la respuesta automática ID:', id);
      await axios.put(`${API_URL}/api/auto-responses/toggle/${id}`, {}, { withCredentials: true });
      setAutoResponses((prevResponses) =>
        prevResponses.map((response) =>
          response._id === id ? { ...response, isActive: !response.isActive } : response
        )
      );
      console.log('Estado cambiado exitosamente para ID:', id);
    } catch (error) {
      console.error('Error al cambiar el estado de la respuesta automática:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log('Eliminando respuesta automática ID:', id);
      await axios.delete(`${API_URL}/api/auto-responses/${id}`, { withCredentials: true });
      setAutoResponses(autoResponses.filter((response) => response._id !== id));
      console.log('Respuesta automática eliminada exitosamente');
    } catch (error) {
      console.error('Error al eliminar la respuesta automática:', error);
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="auto-responses-page">
      <h2>Auto Respuestas</h2>
      
      {/* Botón para crear una nueva auto respuesta */}
      <button className="btn-create" onClick={() => navigate('/admin/auto-responses/new')}>
        <i className="fas fa-plus"></i> Crear nueva respuesta automática
      </button>

      <table className="auto-responses-table">
        <thead>
          <tr>
            <th>Contexto</th>
            <th>Mensaje</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {autoResponses.map((response) => (
            <tr key={response._id}>
              <td>{response.context}</td>
              <td>{response.message}</td>
              <td>{response.isActive ? 'Activo' : 'Inactivo'}</td>
              <td className="action-buttons">
                <button className="btn-toggle" onClick={() => handleToggleActive(response._id)}>
                  <i className={`fas ${response.isActive ? 'fa-toggle-off' : 'fa-toggle-on'}`}></i>
                  {response.isActive ? 'Desactivar' : 'Activar'}
                </button>
                <button className="btn-edit" onClick={() => navigate(`/admin/auto-responses/edit/${response._id}`)}>
                  <i className="fas fa-edit"></i> Editar
                </button>
                <button className="btn-delete" onClick={() => handleDelete(response._id)}>
                  <i className="fas fa-trash"></i> Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AutoResponsesPage;
