import React from 'react';

const AccesoDenegado = () => {
  return (
    <div>
      <h2>Acceso Denegado</h2>
      <p>No tienes los permisos necesarios para acceder a esta página.</p>
    </div>
  );
};

export default AccesoDenegado;
