import React, { useState } from 'react';
import styles from './css/DownloadReports.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const API_URL = process.env.REACT_APP_API_URL;

const DownloadReports = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const fetchFilteredData = (page = 1) => {
    if (!startDate || !endDate) {
      alert('Por favor selecciona las fechas de inicio y fin');
      return;
    }

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];

    setLoading(true);

    // Limpiar los tickets antes de realizar la nueva solicitud
    setTickets([]);

    const url = `${API_URL}/api/reports?startDate=${formattedStartDate}&endDate=${formattedEndDate}&page=${page}&limit=${limit}&format=json`;

    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setTickets(data.tickets); // Aquí se actualizan los tickets según la nueva página
        setTotalPages(data.totalPages);
        setCurrentPage(data.currentPage);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      });
  };

  const downloadReport = () => {
    if (!startDate || !endDate) {
      alert('Por favor selecciona las fechas de inicio y fin');
      return;
    }

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];

    const csvUrl = `${API_URL}/api/reports?startDate=${formattedStartDate}&endDate=${formattedEndDate}&format=csv`;

    fetch(csvUrl, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la descarga del reporte: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'tickets_report.csv';
        link.click();
      })
      .catch((error) => console.error('Error al descargar el reporte:', error));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchFilteredData(newPage);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Reporte de Tickets</h1>

      <div className={styles.filters}>
        <div className={styles.datePickerGroup}>
          <div className={styles.formGroup}>
            <label>Fecha de Inicio</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className={styles.datePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText="Seleccionar fecha de inicio"
            />
          </div>

          <div className={styles.formGroup}>
            <label>Fecha de Fin</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className={styles.datePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText="Seleccionar fecha de fin"
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button onClick={() => fetchFilteredData(1)} className={styles.btnPrimary}>
            Buscar Tickets
          </button>

          <button
            onClick={downloadReport}
            className={styles.btnSecondary}
            disabled={!startDate || !endDate} // Deshabilitar si no hay fechas
          >
            Descargar Reporte en CSV
          </button>
        </div>
      </div>

      <div className={styles.tableResponsive}>
        {loading ? (
          <p>Cargando datos...</p>
        ) : (
          <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Número de Ticket</th>
                  <th>Título</th>
                  <th>Cliente</th>
                  <th>Teléfono</th>
                  <th>Estado</th>
                  <th>Agente Asignado</th>
                  <th>Categoría</th>
                  <th>Fecha de Creación (Local)</th>
                  <th>Fecha de Cierre (Local)</th>
                  <th>Solución</th>
                </tr>
              </thead>
              <tbody>
                {tickets.length > 0 ? (
                  tickets.map((ticket) => (
                    <tr key={ticket.ticketNumber}>
                      <td>{ticket.ticketNumber}</td>
                      <td>{ticket.title}</td>
                      <td>{ticket.customer}</td>
                      <td>{ticket.phoneNumber}</td>
                      <td>{ticket.status}</td>
                      <td>{ticket.assignedTo}</td>
                      <td>{ticket.category}</td>
                      <td>{ticket.localCreatedAt}</td>
                      <td>{ticket.localClosedAt ? ticket.localClosedAt : 'N/A'}</td>
                      <td>{ticket.solution}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No hay tickets disponibles para este rango de fechas.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className={styles.pagination}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={styles.btnPagination}
              >
                Anterior
              </button>
              <span className={styles.mx3}>
                Página {currentPage} de {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={styles.btnPagination}
              >
                Siguiente
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadReports;
