import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getSocket } from '../utils/socket';
import './css/ChatWindow.css';
import ImageModal from './ImageModal';
import MessageInput from './MessageInput';

const API_URL = process.env.REACT_APP_API_URL;

const ChatWindow = ({ selectedTicket, onCloseTicket }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalFileUrl, setModalFileUrl] = useState(null); // Estado para la URL del archivo en el modal
  const [modalFileType, setModalFileType] = useState(null); // Estado para el tipo de archivo en el modal
  const [solution, setSolution] = useState('');
  const [isSolutionModalOpen, setIsSolutionModalOpen] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [reassignmentReason, setReassignmentReason] = useState('');
  const [messages, setMessages] = useState([]);
  const [categories, setCategories] = useState([]); 
  const [selectedCategory, setSelectedCategory] = useState(''); 
  const messagesEndRef = useRef(null);
  const socket = getSocket();

  // Recuperar los mensajes del ticket seleccionado
  useEffect(() => {
    const fetchMessages = async () => {
      if (selectedTicket && selectedTicket._id) {
        try {
          console.log('Recuperando mensajes del ticket:', selectedTicket._id);
          const response = await axios.get(`${API_URL}/api/tickets/${selectedTicket._id}/messages`, {
            withCredentials: true
          });
          setMessages(response.data.messages);
          console.log('Mensajes recuperados:', response.data.messages);
        } catch (error) {
          console.error('Error al obtener los mensajes del ticket:', error);
        }
      }
    };
    fetchMessages();
  }, [selectedTicket]);

  // Función para actualizar los mensajes al enviar uno nuevo
  const updateSelectedTicketMessages = (newMessage) => {
    console.log('Actualizando mensajes con nuevo mensaje:', newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    console.log('Mensajes después de actualizar:', messages);
  };

  // Escuchar actualizaciones del ticket vía Socket.io
  useEffect(() => {
    if (socket && selectedTicket) {
      console.log('Escuchando actualizaciones del ticket en socket:', selectedTicket._id);
      socket.on('ticketUpdated', (data) => {
        if (data.ticketId === selectedTicket._id) {
          console.log('Ticket actualizado recibido vía socket:', data);
          setMessages(data.ticket.messages);
        }
      });

      return () => {
        console.log('Desmontando listeners de ticketUpdated');
        socket.off('ticketUpdated');
      };
    }
  }, [socket, selectedTicket]);

  // Desplazar al final cuando los mensajes cambien
  useEffect(() => {
    if (messagesEndRef.current) {
      console.log('Desplazando al final de los mensajes');
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Obtener agentes para la reasignación
  const handleOpenReassignModal = async () => {
    try {
      console.log('Obteniendo agentes para la reasignación');
      const response = await axios.get(`${API_URL}/api/agents`);
      setAgents(response.data);
      setIsReassignModalOpen(true);
    } catch (error) {
      console.error('Error al obtener agentes:', error);
    }
  };

  // Obtener categorías al cargar el componente
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        console.log('Obteniendo categorías');
        const response = await axios.get(`${API_URL}/api/categories`, {
          withCredentials: true
        });
        setCategories(response.data);
        console.log('Categorías obtenidas:', response.data);
      } catch (error) {
        console.error('Error al obtener categorías:', error);
      }
    };
    fetchCategories();
  }, []);

  // Función para reasignar un ticket
  const handleReassignTicket = async () => {
    if (!selectedAgent || !reassignmentReason.trim()) {
      alert('Por favor selecciona un agente y proporciona un motivo de reasignación');
      return;
    }
  
    try {
      console.log(`Reasignando ticket ${selectedTicket._id} al agente ${selectedAgent}`);
  
      // Realizamos la solicitud de reasignación
      await axios.put(
        `${API_URL}/api/tickets/${selectedTicket._id}/reassign`,
        { agentId: selectedAgent, reassignmentReason },
        { withCredentials: true }
      );
  
      // Cerrar el modal si todo sale bien
      setIsReassignModalOpen(false);
      alert('Ticket reasignado exitosamente');
  
      // Opcional: Actualizar o eliminar el ticket del frontend
      onCloseTicket(selectedTicket._id);
    } catch (error) {
      console.error('Error al reasignar el ticket:', error);
  
      // Verificamos si el error es el específico de que el agente ya tiene un ticket en proceso
      if (error.response && error.response.status === 400 && error.response.data.message === 'El agente ya tiene un ticket en proceso y no puede aceptar más tickets.') {
        alert('El agente ya tiene un ticket en proceso y no puede aceptar más tickets.');
      } else {
        alert('Error al reasignar el ticket');
      }
    }
  };    

  // Función para cerrar el ticket
  const handleCloseTicket = () => {
    setIsSolutionModalOpen(true);
  };

  // Enviar solución y categoría seleccionada
  const submitSolution = async () => {
    if (!solution.trim()) {
      alert('Por favor, ingresa una solución antes de cerrar el ticket');
      return;
    }
    
    if (!selectedCategory) {
      alert('Por favor selecciona una categoría');
      return;
    }
  
    try {
      console.log('Enviando solución para el ticket', selectedCategory);
      const response = await axios.put(
        `${API_URL}/api/tickets/${selectedTicket._id}/close`,
        { solution, categoryId: selectedCategory },
        { withCredentials: true }
      );
  
      console.log('Respuesta del backend al cerrar ticket:', response.data);
  
      setIsSolutionModalOpen(false);
      setSolution('');
      setSelectedCategory('');
      alert('Ticket cerrado exitosamente');
      onCloseTicket(selectedTicket._id);
    } catch (error) {
      console.error('Error al cerrar el ticket:', error);
      alert('Error al cerrar el ticket. Por favor, intenta nuevamente.');
    }
  };  

  // Función para abrir el modal cuando se hace clic en un archivo (imagen o video)
  const openModal = (fileUrl, fileType) => {
    console.log('Abriendo modal para archivo:', fileUrl);
    setModalFileUrl(fileUrl);
    setModalFileType(fileType);
    setModalIsOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    console.log('Cerrando modal');
    setModalIsOpen(false);
    setModalFileUrl(null);
    setModalFileType(null);
  };

  // Renderizar archivos adjuntos de los mensajes
  const renderMessageFile = (msg) => {
    const { fileUrl, fileType, fileName } = msg;
  
    if (fileType?.startsWith('image/')) {
      return (
        <img
          src={fileUrl}
          alt={fileName}
          className="message-file"
          onClick={() => openModal(fileUrl, fileType)}
        />
      );
    } else if (fileType?.startsWith('video/')) {
      return (
        <div className="video-container">
          <video controls className="message-video">
            <source src={fileUrl} type={fileType} />
            Tu navegador no soporta el formato de video.
          </video>
          <div className="expand-icon" onClick={() => openModal(fileUrl, fileType)}>
            ⤢
          </div>
        </div>
      );
    } else if (fileType?.startsWith('audio/')) {
      return (
        <audio controls className="message-file">
          <source src={fileUrl} type={fileType} />
          Tu navegador no soporta el formato de audio.
        </audio>
      );
    } else if (fileType === 'application/pdf') {
      return (
        <div className="pdf-container">
          <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="message-file pdf-link">
            <i className="fas fa-file-pdf pdf-icon"></i> {fileName}
          </a>
        </div>
      );
    } else {
      return (
        <div className="other-file-container">
          <i className="fas fa-file-alt file-icon"></i>
          <span className="file-name">{fileName}</span>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer" download className="download-btn">
            Abrir Archivo
          </a>
        </div>
      );
    }
  };

  return (
    <div className="chat-window">
      <div className="messages">
        {messages.length > 0 ? (
          messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender === 'agent' ? 'agent' : 'client'}`}>
              <span className="sender-label">{msg.sender}</span>
              {msg.message && <p>{msg.message}</p>}
              {msg.fileUrl && renderMessageFile(msg)}
              {msg.timestamp && (
                <span className="message-timestamp">
                  {new Date(msg.timestamp).toLocaleString()}
                </span>
              )}
            </div>
          ))
        ) : (
          <p>No hay mensajes para este ticket.</p>
        )}
        <div ref={messagesEndRef} />
      </div>

      <MessageInput
        selectedTicket={selectedTicket}
        updateSelectedTicketMessages={updateSelectedTicketMessages}
      />

      <div className="action-buttons">
        <button className="action-button" onClick={handleCloseTicket}>Cerrar Ticket</button>
        <button className="action-button" onClick={handleOpenReassignModal}>Reasignar Ticket</button>
      </div>

      {/* Modal para cerrar el ticket */}
      {isSolutionModalOpen && (
        <div className="modal-overlay" onClick={() => setIsSolutionModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Ingresar Solución</h2>
            <textarea
              value={solution}
              onChange={(e) => setSolution(e.target.value)}
              placeholder="Describe cómo se resolvió el ticket..."
              rows="3"
            />
            {/* Selección de categoría */}
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Selecciona una categoría</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>

            <button onClick={submitSolution}>Cerrar Ticket</button>
            <button onClick={() => setIsSolutionModalOpen(false)}>Cancelar</button>
          </div>
        </div>
      )}

      {/* Modal para reasignar el ticket */}
      {isReassignModalOpen && (
  <div className="modal-overlay" onClick={() => setIsReassignModalOpen(false)}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <h2>Reasignar Ticket</h2>
      
      {/* Selección del agente */}
      <select value={selectedAgent} onChange={(e) => setSelectedAgent(e.target.value)}>
        <option value="">Selecciona un agente</option>
        {agents.map((agent) => (
          <option key={agent._id} value={agent._id}>
            {agent.name} ({agent.status})
          </option>
        ))}
      </select>
      
      {/* Campo para el motivo de la reasignación */}
      <textarea
        value={reassignmentReason}
        onChange={(e) => setReassignmentReason(e.target.value)}
        placeholder="Motivo de la reasignación"
        rows="3"
      />
      
      {/* Botón para realizar la reasignación */}
      <button onClick={handleReassignTicket}>Reasignar</button>
      
      {/* Botón para cancelar la acción */}
      <button onClick={() => setIsReassignModalOpen(false)}>Cancelar</button>
    </div>
  </div>
)}


      <ImageModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        fileUrl={modalFileUrl}
        fileType={modalFileType}
      />
    </div>
  );
};

export default ChatWindow;
