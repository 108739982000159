import { io } from 'socket.io-client';

let socket;
const API_URL = process.env.REACT_APP_API_URL || 'https://miportafolio.fun';  // Aseg�rate de que est� usando HTTPS

export const initiateSocketConnection = () => {
  socket = io(API_URL, {
    transports: ['websocket'], // Forzar el uso de WebSocket para evitar contenido mixto
    withCredentials: true,     // Permitir que se env�en las cookies junto con la solicitud
  });
  console.log('Conectado a Socket.IO');
};

export const disconnectSocket = () => {
  console.log('Desconectando de Socket.IO');
  if (socket) socket.disconnect();
};

export const getSocket = () => {
  if (!socket) {
    console.error('Socket no est� inicializado. Llama a initiateSocketConnection primero.');
  }
  return socket;
};
