import React, { useState } from 'react';
import './css/TicketModal.css';
import { FaTimes } from 'react-icons/fa';

// Modal para visualizar medios ampliados
const MediaModal = ({ mediaUrl, mediaType, onClose }) => {
  return (
    <div className="modal-overlay media-modal">
      <div className="modal-content media-modal">
        <button className="close-modal" onClick={onClose}>
          <FaTimes />
        </button>
        {mediaType.startsWith('image/') && (
          <img src={mediaUrl} alt="Imagen ampliada" className="full-media" />
        )}
        {mediaType.startsWith('video/') && (
          <video controls className="full-media">
            <source src={mediaUrl} type={mediaType} />
            Tu navegador no soporta la reproducción de videos.
          </video>
        )}
        {mediaType.startsWith('audio/') && (
          <audio controls className="full-media">
            <source src={mediaUrl} type={mediaType} />
            Tu navegador no soporta la reproducción de audios.
          </audio>
        )}
      </div>
    </div>
  );
};

const TicketModal = ({ ticket, onClose }) => {
  const [selectedMedia, setSelectedMedia] = useState(null); // Estado para URL del medio
  const [mediaType, setMediaType] = useState(null); // Estado para el tipo de medio

  // Función para abrir el modal de medios
  const openMediaModal = (mediaUrl, type) => {
    setSelectedMedia(mediaUrl);
    setMediaType(type);
  };

  // Función para cerrar el modal de medios
  const closeMediaModal = () => {
    setSelectedMedia(null);
    setMediaType(null);
  };

  // Función para renderizar archivos multimedia según el tipo
  const renderMedia = (fileUrl, fileType) => {
    if (!fileUrl) return null;

    if (fileType.startsWith('image/')) {
      return (
        <img
          src={fileUrl}
          alt="Imagen adjunta"
          className="attached-media"
          onClick={() => openMediaModal(fileUrl, fileType)}
          style={{ cursor: 'pointer' }}
        />
      );
    } else if (fileType.startsWith('video/')) {
      return (
        <video
          controls
          className="attached-media"
          onClick={() => openMediaModal(fileUrl, fileType)}
          style={{ cursor: 'pointer' }}
        >
          <source src={fileUrl} type={fileType} />
          Tu navegador no soporta la reproducción de videos.
        </video>
      );
    } else if (fileType.startsWith('audio/')) {
      return (
        <audio
          controls
          className="attached-media"
          onClick={() => openMediaModal(fileUrl, fileType)}
          style={{ cursor: 'pointer' }}
        >
          <source src={fileUrl} type={fileType} />
          Tu navegador no soporta la reproducción de audios.
        </audio>
      );
    } else {
      return (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          Archivo adjunto
        </a>
      );
    }
  };

  // Función para formatear el número de teléfono eliminando el sufijo @c.us
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      return phoneNumber.replace('@c.us', ''); // Elimina el sufijo
    }
    return 'No disponible';
  };

  return (
    <div className="modal-overlay ticket-modal">
      <div className="modal-content ticket-modal">
        <button className="close-modal" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Detalles del Ticket #{ticket.ticketNumber}</h2>

        <div className="ticket-info">
          {/* Actualiza para acceder a los datos del cliente y formatear el número de teléfono */}
          <p><strong>Cliente:</strong> {ticket.customer?.name || 'Desconocido'}</p>
          <p><strong>Teléfono:</strong> {formatPhoneNumber(ticket.customer?.phoneNumber)}</p>
          <p><strong>Email:</strong> {ticket.customer?.email || 'No disponible'}</p>
          <p><strong>Descripción:</strong> {ticket.description}</p>
          <p><strong>Estado:</strong> <span className={`status ${ticket.status}`}>{ticket.status}</span></p>
          <p><strong>Agente Asignado:</strong> {ticket.assignedTo?.name || 'Sin asignar'}</p>
          <p><strong>Solución:</strong> {ticket.solution || 'Aún no hay solución'}</p>
        </div>

        <h3>Conversación</h3>
        <div className="chat-box">
          {ticket.messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.sender === 'agent' ? 'agent' : 'client'}`}>
              <p><strong>{msg.sender === 'agent' ? 'Agente' : 'Cliente'}:</strong></p>
              <p>{msg.message}</p>
              {renderMedia(msg.fileUrl, msg.fileType)}
              <small>{new Date(msg.timestamp).toLocaleString()}</small>
            </div>
          ))}
        </div>
      </div>

      {/* Modal para visualizar medios ampliados */}
      {selectedMedia && (
        <MediaModal
          mediaUrl={selectedMedia}
          mediaType={mediaType}
          onClose={closeMediaModal}
        />
      )}
    </div>
  );
};

export default TicketModal;
