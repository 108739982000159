import React, { useState, useEffect, useCallback } from 'react';
import { FaUser, FaPhone, FaEnvelope, FaHome, FaInfoCircle, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import './css/ContactInfo.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ContactInfo = ({ selectedTicket }) => {
  const [clientInfo, setClientInfo] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
    additionalInfo: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber ? phoneNumber.replace('@c.us', '') : '';
  };

  const formatPhoneNumberForDisplay = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const countryCode = cleaned.substring(0, 3);
    const part1 = cleaned.substring(3, 7);
    const part2 = cleaned.substring(7);
    return `+${countryCode} ${part1}-${part2}`;
  };

  const searchClientByPhone = useCallback(async (phoneNumber) => {
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const response = await axios.get(`${API_URL}/api/clients/phone/${encodedPhoneNumber}`, {
        withCredentials: true,
      });
      
      if (response.data && response.data.data) {
        const client = response.data.data;
        setClientInfo({
          name: client.name || 'No disponible',
          phoneNumber: formatPhoneNumber(client.phoneNumber) || 'No disponible',
          email: client.email || 'No disponible',
          address: client.address || 'No disponible',
          additionalInfo: client.additionalInfo || 'No disponible'
        });
      }
    } catch (error) {
      console.error('Error al buscar el cliente por número de teléfono:', error);
      alert('No se pudo encontrar la información del cliente.');
    }
  }, []);

  useEffect(() => {
    const fetchClientInfo = async (customerId) => {
      try {
        const response = await axios.get(`${API_URL}/api/clients/${customerId}`, { withCredentials: true });
        const { data } = response.data;

        setClientInfo({
          name: data.name || 'No disponible',
          phoneNumber: formatPhoneNumber(data.phoneNumber) || 'No disponible',
          email: data.email || 'No disponible',
          address: data.address || 'No disponible',
          additionalInfo: data.additionalInfo || 'No disponible'
        });
      } catch (error) {
        console.error('Error al obtener los datos del cliente:', error);
        alert('Error al cargar la información del cliente.');
      }
    };

    if (selectedTicket && selectedTicket.customer) {
      if (typeof selectedTicket.customer === 'string') {
        fetchClientInfo(selectedTicket.customer);
      } else if (selectedTicket.customer._id) {
        fetchClientInfo(selectedTicket.customer._id);
      } else if (selectedTicket.customer.phoneNumber) {
        searchClientByPhone(selectedTicket.customer.phoneNumber);
      }
    }

    return () => {
      setClientInfo({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        additionalInfo: ''
      });
    };
  }, [selectedTicket, searchClientByPhone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let customerId = typeof selectedTicket.customer === 'string' 
        ? selectedTicket.customer 
        : selectedTicket.customer._id;

      if (!customerId && selectedTicket.customer.phoneNumber) {
        const encodedPhoneNumber = encodeURIComponent(selectedTicket.customer.phoneNumber);
        const response = await axios.get(`${API_URL}/api/clients/phone/${encodedPhoneNumber}`, {
          withCredentials: true
        });

        if (response.data && response.data.data && response.data.data._id) {
          customerId = response.data.data._id;  
        } else {
          alert('Error: No se encontró el ID del cliente.');
          setIsSubmitting(false);
          return;
        }
      }

      if (!customerId) {
        alert('Error: No se encontró el ID del cliente. No se puede actualizar.');
        setIsSubmitting(false);
        return;
      }

      const updatedInfo = {
        ...clientInfo,
        phoneNumber: formatPhoneNumber(clientInfo.phoneNumber)
      };

      const response = await axios.put(`${API_URL}/api/clients/${customerId}`, updatedInfo, { withCredentials: true });

      if (response.data.success) {
        alert('Información del cliente actualizada exitosamente');
        setIsEditing(false);
      } else {
        alert('Error al actualizar la información del cliente');
      }
    } catch (error) {
      alert('Error al actualizar la información del cliente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!selectedTicket || !selectedTicket.customer) {
    return <div className="contact-info">Selecciona un ticket para ver la información del contacto.</div>;
  }

  return (
    <div className="contact-info card">
      <h3>Información del Contacto</h3>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <div>
            <label><FaUser /> Nombre:</label>
            <input type="text" name="name" value={clientInfo.name} onChange={handleChange} disabled={isSubmitting} />
          </div>
          <div>
            <label><FaPhone /> Teléfono:</label>
            <input type="text" name="phoneNumber" value={clientInfo.phoneNumber} onChange={handleChange} disabled={isSubmitting} />
          </div>
          <div>
            <label><FaEnvelope /> Email:</label>
            <input type="email" name="email" value={clientInfo.email} onChange={handleChange} disabled={isSubmitting} />
          </div>
          <div>
            <label><FaHome /> Dirección:</label>
            <input type="text" name="address" value={clientInfo.address} onChange={handleChange} disabled={isSubmitting} />
          </div>
          <div>
            <label><FaInfoCircle /> Información Adicional:</label>
            <textarea name="additionalInfo" value={clientInfo.additionalInfo} onChange={handleChange} disabled={isSubmitting} />
          </div>
          <button type="submit" disabled={isSubmitting}><FaSave /> Guardar Cambios</button>
          <button type="button" onClick={() => setIsEditing(false)} disabled={isSubmitting}><FaTimes /> Cancelar</button>
        </form>
      ) : (
        <div>
          <p><FaUser /> Nombre: {clientInfo.name || 'No disponible'}</p>
          <p><FaPhone /> Teléfono: {clientInfo.phoneNumber ? formatPhoneNumberForDisplay(clientInfo.phoneNumber) : 'No disponible'}</p>
          <p><FaEnvelope /> Email: {clientInfo.email || 'No disponible'}</p>
          <p><FaHome /> Dirección: {clientInfo.address || 'No disponible'}</p>
          <p><FaInfoCircle /> Información Adicional: {clientInfo.additionalInfo || 'No disponible'}</p>

          <button className="edit-button" onClick={() => setIsEditing(true)} title="Editar">
            <FaEdit className="edit-icon" />
            Editar
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;
