import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './css/AdminNavbar.module.css';
import { FaSignOutAlt, FaBars, FaUser, FaTachometerAlt, FaReplyAll, FaFolder, FaDownload, FaRobot } from 'react-icons/fa'; 
import Avatar from 'react-avatar';
import TicketModal from './TicketModal';  // Importa el modal para mostrar detalles del ticket
import ErrorModal from './ErrorModal';    // Importa el modal de error

const API_URL = process.env.REACT_APP_API_URL;

const AdminNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(''); // Estado para manejar el número de ticket
  const [ticketDetails, setTicketDetails] = useState(null); // Estado para los detalles del ticket
  const [showModal, setShowModal] = useState(false); // Estado para mostrar el modal de detalles del ticket
  const [showErrorModal, setShowErrorModal] = useState(false); // Estado para mostrar el modal de error
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminName = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/agents/me`, { withCredentials: true });
        setAdminName(response.data.name);
        setIsLoading(false);
      } catch (error) {
        console.error('Error al obtener el nombre del administrador:', error);
        setError('Error al cargar los datos.');
        setIsLoading(false);
      }
    };

    fetchAdminName();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post(`${API_URL}/api/auth/logout`, {}, { withCredentials: true });
      navigate('/login');
      setMenuOpen(false);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  const handleAvatarClick = () => {
    navigate('/admin/dashboard');
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleMenuOptionClick = (route) => {
    navigate(route);
    setMenuOpen(false);
  };

  // Función para manejar la búsqueda de tickets
  const handleSearchTicket = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_URL}/api/tickets/ticketNumber/${ticketNumber}`, { withCredentials: true });
      if (response.data && response.data.data) {
        setTicketDetails(response.data.data); // Guardar los detalles del ticket
        setShowModal(true); // Mostrar el modal de detalles del ticket
        setShowErrorModal(false); // Asegurarse de que el modal de error no esté visible
      } else {
        setShowErrorModal(true); // Mostrar el modal de error si no se encuentra el ticket
      }
      setMenuOpen(false); // Cerrar el menú después de buscar
    } catch (error) {
      console.error('Error al buscar el ticket:', error);
      setShowErrorModal(true); // Mostrar el modal de error en caso de error
    }
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.navbarLeft}>
        <Avatar 
          name={adminName || 'Admin'} 
          round={true} 
          size="40" 
          className={styles.avatar} 
          style={{ cursor: 'pointer' }} 
          onClick={handleAvatarClick}
        />
        
        <div className={styles.adminInfo}>
          {isLoading ? (
            <h3>Cargando...</h3>
          ) : error ? (
            <h3>{error}</h3>
          ) : (
            <h3>{adminName}</h3>
          )}
          <p>Administrador</p>
        </div>
      </div>

      {/* Formulario de búsqueda de ticket */}
      <form onSubmit={handleSearchTicket} className={styles.searchForm}>
        <input 
          type="text"
          value={ticketNumber}
          onChange={(e) => setTicketNumber(e.target.value)}
          placeholder="Buscar Ticket por Número"
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>Buscar</button>
      </form>

      <div className={styles.navbarRight}>
        <FaBars onClick={toggleMenu} className={styles.menuIcon} />

        <div className={`${styles.dropdownMenu} ${menuOpen ? styles.show : ''}`}>
          <button onClick={() => handleMenuOptionClick('/admin/dashboard')}>
            <FaTachometerAlt /> Dashboard
          </button>
          <button onClick={() => handleMenuOptionClick('/admin/user-management')}>
            <FaUser /> Gestionar Usuarios
          </button>
          <button onClick={() => handleMenuOptionClick('/admin/auto-responses')}>
            <FaReplyAll /> Auto Respuestas
          </button>
          <button onClick={() => handleMenuOptionClick('/admin/categories')}>
            <FaFolder /> Mantenimiento de Categorías
          </button>
          <button onClick={() => handleMenuOptionClick('/admin/download-reports')}>
            <FaDownload /> Descargar Reportes
          </button>
          <button onClick={() => handleMenuOptionClick('/admin/venom-session')}>
            <FaRobot /> Venom-Bot  {/* Nueva opción para Venom-Bot */}
          </button>
          <button onClick={handleLogout}>
            <FaSignOutAlt /> Cerrar Sesión
          </button>
        </div>
      </div>

      {/* Modal para mostrar los detalles del ticket */}
      {showModal && (
        <TicketModal 
          ticket={ticketDetails} 
          onClose={() => setShowModal(false)} 
        />
      )}

      {/* Modal de error si no se encuentra el ticket */}
      {showErrorModal && (
        <ErrorModal 
          message="Ticket no encontrado. Verifique el número de ticket." 
          onClose={() => setShowErrorModal(false)} 
        />
      )}
    </div>
  );
};

export default AdminNavbar;
