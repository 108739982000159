import React from 'react';
import './css/TicketList.css';
import TicketItem from './TicketItem'; // Importamos el componente TicketItem

const TicketList = ({ tickets, selectTicket, flashTicketId, selectedTicket }) => {
  console.log('Tickets en TicketList:', tickets); // Verificar los tickets que se pasan a la lista

  return (
    <div className="ticket-list">
      <div className="ticket-list-inner">
        {tickets && tickets.length > 0 ? (
          tickets.map((ticket, index) => {
            if (!ticket || !ticket.title) {
              return (
                <div key={ticket?._id || index} className="ticket-item">
                  <h4>Sin título</h4>
                  <p>Descripción no disponible</p>
                </div>
              );
            }

            return (
              <TicketItem 
                key={ticket._id} 
                ticket={ticket} 
                flashTicketId={flashTicketId} 
                selectTicket={selectTicket}
                isSelected={selectedTicket && selectedTicket._id === ticket._id} // Verificar si este ticket es el seleccionado
              />
            );
          })
        ) : (
          <p>No hay tickets disponibles</p>
        )}
      </div>
    </div>
  );
};

export default TicketList;
