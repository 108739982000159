import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Overlay, FormWrapper, Title, InputWrapper, Input, Button, ErrorMessage } from './css/LoginStyles';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [validEmail, setValidEmail] = useState(true);  // Para validar el email
  const [validPassword, setValidPassword] = useState(true);  // Para validar la longitud de la contraseña
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones básicas
    if (!validEmail || password.length < 6) {
      setError('Verifica tus credenciales.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        { email, password },
        { withCredentials: true }
      );

      if (response.status === 200) {
        const user = response.data.user;

        if (!user.isActive) {
          setError('Tu cuenta está inactiva. Contacta al administrador.');
          return;
        }

        if (user.role === 'admin') {
          navigate('/admin/dashboard');
        } else {
          navigate('/agent/dashboard');
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setError('Tu cuenta está inactiva. Contacta al administrador.');
        } else if (error.response.status === 401) {
          setError('Credenciales incorrectas. Inténtalo de nuevo.');
        } else {
          setError('Ocurrió un error. Inténtalo de nuevo.');
        }
      } else {
        setError('Ocurrió un error al conectar con el servidor.');
      }
    }
  };

  // Validar email en tiempo real
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(value));
    setEmail(value);
  };

  // Validar contraseña (mínimo 6 caracteres)
  const validatePassword = (value) => {
    setValidPassword(value.length >= 6);
    setPassword(value);
  };

  return (
    <Container>
      <Overlay />
      <FormWrapper>
        <Title>Iniciar Sesión</Title>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Correo Electrónico"
              value={email}
              onChange={(e) => validateEmail(e.target.value)}
              className={validEmail ? '' : 'error'}  // Añade una clase en lugar de estilo en línea
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => validatePassword(e.target.value)}
              className={validPassword ? '' : 'error'}  // Añade una clase en lugar de estilo en línea
            />
          </InputWrapper>
          <Button type="submit">Iniciar Sesión</Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </form>
      </FormWrapper>
    </Container>
  );
}

export default Login;
