import React from 'react';
import './css/ImageModal.css';

const ImageModal = ({ isOpen, onClose, fileUrl, fileType }) => {
  if (!isOpen) return null;

  const renderContent = () => {
    if (fileType.includes('image')) {
      return <img src={fileUrl} alt="Imagen en tamaño completo" className="modal-image" />;
    } else if (fileType.includes('video')) {
      return <video src={fileUrl} controls className="modal-video" />;
    } else {
      // Para otros tipos de archivos (PDF, documentos, etc.)
      return (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="modal-file-link">
          Descargar archivo
        </a>
      );
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {renderContent()}
        <button className="close-button" onClick={onClose} aria-label="Cerrar modal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="black" />
            <path d="M15 9L9 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 9L15 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
