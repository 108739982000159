import React from 'react';
import AdminNavbar from './AdminNavbar';  // Ruta del componente AdminNavbar

const AdminLayout = ({ children }) => {
  return (
    <div>
      <AdminNavbar /> {/* El Navbar se encargará de obtener el nombre dinámicamente */}
      <main style={{ padding: '20px' }}>{children}</main> {/* Contenido dinámico */}
    </div>
  );
};

export default AdminLayout;
