import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import UserForm from '../components/UserForm'; // El formulario de usuario
import styles from './css/UserManagement.module.css'; // Estilos para la página
import Modal from '../components/Modal'; // Componente del modal
import Swal from 'sweetalert2'; // Librería para mejorar las alertas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faBan, faCheck } from '@fortawesome/free-solid-svg-icons'; // Iconos para inactivar y activar
import ReactPaginate from 'react-paginate'; // Librería para la paginación

const API_URL = process.env.REACT_APP_API_URL; // Definir la constante para la URL de la API

const UserManagementPage = () => {
  const [users, setUsers] = useState([]); // Estado para los usuarios
  const [filteredUsers, setFilteredUsers] = useState([]); // Estado para usuarios filtrados
  const [editingUser, setEditingUser] = useState(null); // Estado para editar un usuario
  const [showModal, setShowModal] = useState(false); // Estado para mostrar el modal
  const [searchQuery, setSearchQuery] = useState(''); // Estado para la barra de búsqueda
  const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual de la paginación

  const usersPerPage = 5; // Número de usuarios por página

  // Memoize config para evitar recrearlo en cada render
  const config = useMemo(() => ({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Obtener el token de autenticación desde localStorage
    },
    withCredentials: true,
  }), []); // Se crea una vez y no cambia, por lo que no tiene dependencias

  // Función para obtener los usuarios
  const fetchUsers = useCallback(async () => {
    try {
      console.log('Enviando solicitud GET a:', `${API_URL}/api/users`);
      const response = await axios.get(`${API_URL}/api/users`, config);
      console.log('Usuarios recibidos:', response.data.data); // Log de los usuarios recibidos
      setUsers(response.data.data);
      setFilteredUsers(response.data.data); // Inicialmente, todos los usuarios están en filteredUsers
    } catch (error) {
      console.error('Error al cargar usuarios:', error); // Log del error si falla la solicitud
      Swal.fire('Error', 'Hubo un problema al cargar los usuarios.', 'error');
    }
  }, [config]);

  // Cargar usuarios desde el backend
  useEffect(() => {
    console.log('Cargando usuarios...');
    fetchUsers();
  }, [fetchUsers]);

  // Filtrar usuarios según la búsqueda
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    console.log('Filtrando usuarios por:', query);
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  // Confirmación para eliminar usuario
  const confirmDeleteUser = (userId) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  // Función para eliminar un usuario
  const deleteUser = async (userId) => {
    try {
      await axios.delete(`${API_URL}/api/users/${userId}`, config);
      Swal.fire('Eliminado', 'El usuario ha sido eliminado.', 'success');
      fetchUsers(); // Recargar lista de usuarios después de eliminar
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al eliminar el usuario.', 'error');
    }
  };

  // Confirmación para inactivar usuario
  const confirmDeactivateUser = (userId) => {
    Swal.fire({
      title: '¿Inactivar usuario?',
      text: "El usuario será inactivado pero no eliminado.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, inactivarlo',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        deactivateUser(userId);
      }
    });
  };

  // Función para inactivar un usuario
  const deactivateUser = async (userId) => {
    try {
      await axios.patch(`${API_URL}/api/users/${userId}/deactivate`, {}, config);
      Swal.fire('Inactivado', 'El usuario ha sido inactivado.', 'success');
      fetchUsers(); // Recargar lista de usuarios después de inactivar
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al inactivar el usuario.', 'error');
    }
  };

  // Función para activar un usuario
  const activateUser = async (userId) => {
    try {
      await axios.patch(`${API_URL}/api/users/${userId}/activate`, {}, config);
      Swal.fire('Activado', 'El usuario ha sido activado.', 'success');
      fetchUsers();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al activar el usuario.', 'error');
    }
  };

  // Abrir el modal para crear o editar usuario
  const openModal = (user = null) => {
    setEditingUser(user);
    setShowModal(true);
  };

  // Cerrar el modal
  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  // Función para manejar el cambio de página en la paginación
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * usersPerPage;
  const currentUsers = filteredUsers.slice(offset, offset + usersPerPage);

  // Función para manejar el éxito al crear/editar un usuario
  const handleSuccess = () => {
    Swal.fire('Usuario guardado', 'El usuario ha sido creado/actualizado exitosamente.', 'success');
    closeModal();
    fetchUsers(); // Recargar lista
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>Gestión de Usuarios</h1>

      {/* Barra de búsqueda */}
      <div className={styles.searchBar}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Buscar usuario por nombre o email"
        />
      </div>

      {/* Botón de añadir usuario */}
      <button onClick={() => openModal()} className={styles.addBtn}>Añadir Usuario</button>

      <h2 className={styles.listTitle}>Lista de Usuarios</h2>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Rol</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.length > 0 ? (
            currentUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td className={styles.actionButtons}>
                  <button onClick={() => openModal(user)} className={styles.editBtn}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                  </button>
                  {user.isActive ? (
                    <button onClick={() => confirmDeactivateUser(user._id)} className={styles.deactivateBtn}>
                      <FontAwesomeIcon icon={faBan} /> Inactivar
                    </button>
                  ) : (
                    <button onClick={() => activateUser(user._id)} className={styles.activateBtn}>
                      <FontAwesomeIcon icon={faCheck} /> Activar
                    </button>
                  )}
                  <button onClick={() => confirmDeleteUser(user._id)} className={styles.deleteBtn}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className={styles.noData}>No hay usuarios disponibles</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Paginación */}
      <ReactPaginate
        previousLabel={'← Anterior'}
        nextLabel={'Siguiente →'}
        breakLabel={'...'}
        pageCount={Math.ceil(filteredUsers.length / usersPerPage)}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        activeClassName={styles.activePage}
      />

      {showModal && (
        <Modal onClose={closeModal}>
          <div className={styles.modalContent}>
            <h2 className={styles.modalHeader}>{editingUser ? 'Editar Usuario' : 'Añadir Usuario'}</h2>
            <UserForm fetchUsers={fetchUsers} editingUser={editingUser} setEditingUser={setEditingUser} onSuccess={handleSuccess} />
            <div className={styles.modalButtons}>
              <button onClick={closeModal} className={styles.cancelBtn}>Cancelar</button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserManagementPage;
