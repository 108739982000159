// src/components/Modal.js
import React, { useEffect } from 'react';
import styles from './css/Modal.module.css';

const Modal = ({ onClose, children }) => {

  // Cerrar el modal con la tecla ESC
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className={styles.closeBtn}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
