import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import AgentDashboard from './pages/AgentDashboard';
import AutoResponsePage from './components/AutoResponsePage';
import AutoResponseForm from './components/AutoResponseForm';
import ProtectedRoute from './components/ProtectedRoute';
import AccesoDenegado from './pages/AccesoDenegado';
import AdminLayout from './components/AdminLayout';
import CategoryManagement from './components/CategoryManagement';
import { initiateSocketConnection, getSocket, disconnectSocket } from './utils/socket';

// Importar la página de administración de usuarios y los nuevos componentes
import UserManagementPage from './pages/UserManagementPage';
import ChartCustomization from './components/ChartCustomization';  // Nueva sección para gráficos
import DownloadReports from './components/DownloadReports';  // Nueva sección para reportes
import VenomSessionPage from './pages/VenomSessionPage';  // Importar la página de Venom-Bot

function App() {
  const [selectedTicket, setSelectedTicket] = useState(null);

  const selectTicket = (ticket) => {
    console.log('[App] Ticket seleccionado:', ticket);
    setSelectedTicket(ticket);
  };

  useEffect(() => {
    initiateSocketConnection();
    const socket = getSocket();
    
    console.log('[App] Iniciando conexión con Socket.IO...');
    console.log('[App] Estado inicial del socket:', socket ? socket.connected : false);

    if (socket) {
      socket.on('connect', () => {
        console.log('[App] Socket conectado:', socket.id);
      });

      socket.on('disconnect', () => {
        console.log('[App] Socket desconectado');
      });
    }

    return () => {
      console.log('[App] Desmontando eventos de socket...');
      if (socket) {
        socket.off('connect');
        socket.off('disconnect');
      }
      disconnectSocket();
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* Ruta principal redirige a login */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        
        {/* Panel de administración protegido para admins dentro de AdminLayout */}
        <Route 
          path="/admin/dashboard" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <AdminDashboard />
              </AdminLayout>
            </ProtectedRoute>
          } 
        />

        {/* Página de acceso denegado */}
        <Route path="/acceso-denegado" element={<AccesoDenegado />} />

        {/* Panel de agente protegido para agentes */}
        <Route
          path="/agent/dashboard"
          element={
            <ProtectedRoute role="agent">
              <AgentDashboard
                socket={getSocket()}
                selectTicket={selectTicket}
                selectedTicket={selectedTicket}
                setSelectedTicket={setSelectedTicket}
              />
            </ProtectedRoute>
          }
        />

        {/* Página de Auto Respuestas protegida para admins dentro de AdminLayout */}
        <Route
          path="/admin/auto-responses"
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <AutoResponsePage />
              </AdminLayout>
            </ProtectedRoute>
          }
        />

        {/* Crear nueva auto respuesta dentro de AdminLayout */}
        <Route
          path="/admin/auto-responses/new"
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <AutoResponseForm />
              </AdminLayout>
            </ProtectedRoute>
          }
        />

        {/* Editar auto respuesta dentro de AdminLayout */}
        <Route
          path="/admin/auto-responses/edit/:id"
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <AutoResponseForm />
              </AdminLayout>
            </ProtectedRoute>
          }
        />

        {/* Administración de usuarios protegida dentro de AdminLayout */}
        <Route 
          path="/admin/user-management" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <UserManagementPage />
              </AdminLayout>
            </ProtectedRoute>
          } 
        />

        {/* Mantenimiento de categorías protegido dentro de AdminLayout */}
        <Route 
          path="/admin/categories" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <CategoryManagement />
              </AdminLayout>
            </ProtectedRoute>
          } 
        />

        {/* Nueva ruta: Personalización de gráficos */}
        <Route 
          path="/admin/chart-customization" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <ChartCustomization />  {/* Página para personalizar gráficos */}
              </AdminLayout>
            </ProtectedRoute>
          } 
        />

        {/* Nueva ruta: Descarga de reportes */}
        <Route 
          path="/admin/download-reports" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <DownloadReports />  {/* Página para descargar reportes */}
              </AdminLayout>
            </ProtectedRoute>
          } 
        />

        {/* Nueva ruta: Página de inicio de sesión de Venom-Bot */}
        <Route 
          path="/admin/venom-session" 
          element={
            <ProtectedRoute role="admin">
              <AdminLayout>
                <VenomSessionPage />  {/* Página de inicio de sesión de Venom-Bot */}
              </AdminLayout>
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
