import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Picker from 'emoji-picker-react';
import './css/MessageInput.css';

const MessageInput = ({ selectedTicket }) => {
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false); // Para gestionar el estilo de arrastrar sobre el input

  const textareaRef = useRef(null); // Referencia para el textarea
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSendMessage = async () => {
    if (!selectedTicket || (newMessage.trim() === '' && !selectedFile)) {
      return;
    }

    setIsSending(true);

    const formData = new FormData();
    if (newMessage.trim() !== '') {
      formData.append('message', newMessage.trim());
    }
    formData.append('sender', 'agent');
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    try {
      await axios.post(
        `${API_URL}/api/tickets/${selectedTicket._id}/messages`,
        formData,
        { withCredentials: true }
      );

      setNewMessage(''); // Limpiar el texto
      setSelectedFile(null); // Limpiar el archivo adjunto
      setFilePreviewUrl(null); // Limpiar la previsualización
      console.log('Mensaje enviado correctamente');
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    } finally {
      setIsSending(false);

      // Foco automático en el textarea después de enviar con un pequeño retraso
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 100); // 100ms de retraso para asegurar el foco
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
    const fileUrl = URL.createObjectURL(file);
    setFilePreviewUrl(fileUrl);
  };

  useEffect(() => {
    return () => {
      if (filePreviewUrl) {
        URL.revokeObjectURL(filePreviewUrl);
      }
    };
  }, [filePreviewUrl]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const addEmoji = (emojiObject) => {
    setNewMessage(newMessage + emojiObject.emoji);
    setShowEmojiPicker(false);

    // Foco de nuevo en el textarea después de seleccionar un emoji
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 100); // 100ms de retraso para asegurar el foco
  };

  // Para manejar el arrastre y soltar archivos
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true); // Cambiar estado cuando se arrastra sobre el área
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false); // Quitar el estilo de arrastrar

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileChange(file); // Procesar el archivo soltado
    }
  };

  const handleDragLeave = () => {
    setIsDragOver(false); // Cambiar estado cuando se sale del área
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showEmojiPicker && !event.target.closest('.emoji-picker-react')) {
        setShowEmojiPicker(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && showEmojiPicker) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showEmojiPicker]);

  // Asegurar que el textarea esté enfocado al montar el componente
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <div
      className={`input-area ${isDragOver ? 'drag-over' : ''}`} // Añadir clase cuando esté arrastrando
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      {showEmojiPicker && (
        <div className="emoji-picker-react">
          <Picker onEmojiClick={addEmoji} />
        </div>
      )}

      {filePreviewUrl && (
        <div className="file-preview">
          {selectedFile.type.startsWith('image/') ? (
            <img src={filePreviewUrl} alt="Vista previa del archivo" className="file-preview-image" />
          ) : selectedFile.type.startsWith('video/') ? (
            <video src={filePreviewUrl} controls className="file-preview-video" />
          ) : (
            <p>{selectedFile.name}</p>
          )}
          <button className="remove-file-btn" onClick={() => {
            setSelectedFile(null);
            setFilePreviewUrl(null);
          }}>X</button>
        </div>
      )}

      <div className="input-wrapper">
        <textarea
          ref={textareaRef}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Escribe un mensaje..."
          rows="1"
          disabled={isSending}
        />

        <div className="emoji-upload-buttons">
          <button
            type="button"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="emoji-btn"
            disabled={isSending}
          >
            😀
          </button>

          <div className="file-upload">
            <label htmlFor="file-upload" className="custom-file-upload">
              <i className="fas fa-paperclip"></i>
            </label>
            <input
              id="file-upload"
              className="file-upload-input"
              type="file"
              onChange={(e) => handleFileChange(e.target.files[0])}
              disabled={isSending}
            />
          </div>

          <button
            onClick={handleSendMessage}
            className="send-btn"
            disabled={isSending}
          >
            <i className="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
