import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import TicketList from '../components/TicketList';
import ChatWindow from '../components/ChatWindow';
import ContactInfo from '../components/ContactInfo';
import './css/AgentDashboard.css';
import backgroundImage from '../assets/background.jpg'; // Importa la imagen
import newTicketSound from '../assets/new-ticket-sound.mp3';

const API_URL = process.env.REACT_APP_API_URL;

const AgentDashboard = ({ selectTicket, selectedTicket, setSelectedTicket, socket }) => {
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [pendingTickets, setPendingTickets] = useState([]); // Para los tickets pendientes
  const [agentId, setAgentId] = useState(null);
  const [agentName, setAgentName] = useState('');
  const [agentStatus, setAgentStatus] = useState('offline');
  const [flashTicketId, setFlashTicketId] = useState(null); // Para hacer "titilar" los tickets con mensajes nuevos
  const [pendingSoundTickets, setPendingSoundTickets] = useState([]);
  const [userHasEnabledSound, setUserHasEnabledSound] = useState(false); // Verificar si el usuario habilitó el sonido

  // Estilos del dashboard
  const dashboardStyle = {
    display: 'flex',
    height: '100vh',
    paddingTop: '70px',
    paddingLeft: '20px',
    paddingRight: '20px',
    gap: '20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundImage: `url(${backgroundImage})`, // Usa la imagen importada aquí
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  // Manejar la habilitación del sonido por parte del usuario
  const enableSound = () => {
    setUserHasEnabledSound(true); // Habilitamos el sonido al hacer clic en el botón
  };

  // Función para reproducir el sonido cuando llega un nuevo ticket
  const playNewTicketSound = useCallback((ticketId) => {
    if (userHasEnabledSound) { // Verificamos si el usuario ha habilitado el sonido
      const audio = new Audio(newTicketSound);
      audio.loop = true; // Hacer que el sonido se repita hasta que se detenga
      audio.play();

      setPendingSoundTickets((prevTickets) => [...prevTickets, { ticketId, audio }]);
    }
  }, [userHasEnabledSound]);

  // Función para detener el sonido de un ticket
  const stopNewTicketSound = useCallback((ticketId) => {
    const ticketSound = pendingSoundTickets.find((t) => t.ticketId === ticketId);
    if (ticketSound) {
      ticketSound.audio.pause(); // Pausar el sonido
      ticketSound.audio.currentTime = 0; // Reiniciar el audio para futuras repeticiones
      setPendingSoundTickets((prevTickets) => prevTickets.filter((t) => t.ticketId !== ticketId)); // Eliminar de la lista de sonidos pendientes
    }
  }, [pendingSoundTickets]);

  // Obtener la información del agente y unirse a la sala correspondiente
  useEffect(() => {
    const fetchUserIdAndJoinRoom = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/agents/me`, { withCredentials: true });
        const { userId, name, status } = response.data;

        if (userId) {
          setAgentId(userId);
          setAgentName(name);
          setAgentStatus(status || 'offline');

          if (socket) {
            socket.emit('joinRoom', userId);
          }
        }
      } catch (error) {
        console.error('Error al obtener el userId:', error);
      }
    };

    if (socket) {
      fetchUserIdAndJoinRoom();
    }
  }, [socket]);

  // Obtener los tickets asignados y pendientes al agente
  useEffect(() => {
    const fetchAssignedAndPendingTickets = async () => {
      try {
        // Obtener tickets asignados
        const response = await axios.get(`${API_URL}/api/agents/assigned-tickets`, { withCredentials: true });
        const openTickets = response.data.filter(ticket => ticket.status === 'open');
        setAssignedTickets(openTickets);

        // Obtener tickets pendientes
        const pendingResponse = await axios.get(`${API_URL}/api/tickets/pending`, { withCredentials: true });
        setPendingTickets(pendingResponse.data.data); // Almacenar los tickets pendientes
        console.log('Tickets pendientes iniciales:', pendingResponse.data.data);
      } catch (error) {
        console.error('Error al cargar los tickets asignados o pendientes:', error);
      }
    };

    fetchAssignedAndPendingTickets();
  }, []);

  // Actualizar el ticket seleccionado y detener el sonido si está en reproducción
  const memoizedSetSelectedTicket = useCallback((ticket) => {
    setSelectedTicket(ticket);

    // Detener el sonido si seleccionan un ticket con sonido activo
    if (ticket) {
      stopNewTicketSound(ticket._id);
      setFlashTicketId(null); // Dejar de titilar cuando seleccionan el ticket
    }
  }, [setSelectedTicket, stopNewTicketSound]);

  useEffect(() => {
    if (socket && agentId) {
      // Nuevo ticket
      socket.on('newTicket', (ticketData) => {
        setAssignedTickets((prevTickets) => [...prevTickets, ticketData.ticket]);
        playNewTicketSound(ticketData.ticket._id); // Reproduce el sonido cuando llegue un nuevo ticket
      });
  
      // Ticket asignado
      socket.on('newTicketAssigned', (ticketData) => {
        console.log('Evento newTicketAssigned recibido:', ticketData);
  
        // Primero, eliminamos el ticket de la lista de pendientes (si estaba ahí)
        setPendingTickets((prevTickets) => {
          const updatedTickets = prevTickets.filter((ticket) => ticket._id !== ticketData.ticket._id);
          console.log('Tickets pendientes después de la asignación:', updatedTickets);
          return updatedTickets;
        });
  
        // Luego, lo agregamos a la lista de asignados **si este agente es el destinatario del ticket**
        if (String(ticketData.ticket.assignedTo) === String(agentId)) {
          console.log(`El ticket #${ticketData.ticket._id} ha sido asignado a este agente.`);
          setAssignedTickets((prevTickets) => [...prevTickets, ticketData.ticket]);
          playNewTicketSound(ticketData.ticket._id); // Reproduce el sonido cuando el ticket se asigna
        }
      });
  
      // Ticket actualizado
      socket.on('ticketUpdated', (updatedTicket) => {
        console.log('Evento ticketUpdated recibido:', updatedTicket);
        if (updatedTicket.status === 'closed') {
          setAssignedTickets((prevTickets) => prevTickets.filter((ticket) => ticket._id !== updatedTicket._id));
          
          // Asegúrate de eliminar el ticket de la lista de pendientes si está ahí
          setPendingTickets((prevTickets) => prevTickets.filter((ticket) => ticket._id !== updatedTicket._id));
  
          if (selectedTicket && selectedTicket._id === updatedTicket._id) {
            memoizedSetSelectedTicket(null); // Deseleccionar el ticket cerrado
          }
        } else if (updatedTicket.status === 'pending') {
          // Evitar duplicados en la lista de pendientes
          setPendingTickets((prevTickets) => {
            const existsInPending = prevTickets.some((ticket) => ticket._id === updatedTicket._id);
            return existsInPending ? prevTickets : [...prevTickets, updatedTicket];
          });
        } else {
          setAssignedTickets((prevTickets) => prevTickets.map((ticket) =>
            ticket._id === updatedTicket._id ? updatedTicket : ticket
          ));
  
          // Aquí, si el ticket actualizado no está seleccionado, hacemos que titile
          if (!selectedTicket || selectedTicket._id !== updatedTicket._id) {
            setFlashTicketId(updatedTicket._id); // El ticket comenzará a titilar
          }
  
          if (selectedTicket && updatedTicket._id === selectedTicket._id) {
            memoizedSetSelectedTicket(updatedTicket); // Actualizar el ticket seleccionado
            stopNewTicketSound(updatedTicket._id); // Detener el sonido al seleccionar el ticket actualizado
          }
        }
      });
  
      // Escuchar el evento ticketPending para tickets pendientes en tiempo real
      socket.on('ticketPending', (ticketData) => {
        console.log('Evento ticketPending recibido:', ticketData);
        // Verificar que el ticket no esté ya en la lista antes de agregarlo
        setPendingTickets((prevTickets) => {
          const existsInPending = prevTickets.some((ticket) => ticket._id === ticketData.ticket._id);
          return existsInPending ? prevTickets : [...prevTickets, ticketData.ticket];
        });
      });
  
      // Ticket reasignado
      socket.on('ticketReassigned', (reassignedTicket) => {
        console.log('Evento ticketReassigned recibido:', reassignedTicket);
        const { ticket } = reassignedTicket;
        setAssignedTickets((prevTickets) => {
          const updatedTickets = prevTickets.filter((t) => t._id !== ticket._id);
  
          if (String(ticket.assignedTo) === String(agentId)) {
            updatedTickets.push(ticket); // Agregar el ticket si es reasignado al agente actual
            playNewTicketSound(ticket._id); // Reproduce el sonido cuando el ticket es reasignado
          }
  
          return updatedTickets;
        });
  
        // Detener el sonido al seleccionar el ticket reasignado
        if (selectedTicket && selectedTicket._id === ticket._id) {
          memoizedSetSelectedTicket(ticket); // Seleccionar el ticket reasignado
          stopNewTicketSound(ticket._id); // Detener el sonido del ticket reasignado
        }
      });
  
      // Escuchar el evento removePendingTicket para eliminar el ticket de la lista de pendientes
      socket.on('removePendingTicket', (data) => {
        console.log('Evento removePendingTicket recibido:', data);
        // Solo eliminar el ticket de la lista de pendientes si no fue asignado a este agente
        if (String(data.assignedTo) !== String(agentId)) {
          setPendingTickets((prevTickets) => {
            const updatedTickets = prevTickets.filter((ticket) => ticket._id !== data.ticketId);
            console.log('Tickets pendientes después de eliminar con removePendingTicket:', updatedTickets);
            return updatedTickets;
          });
        }
      });
  
      // Eliminar listeners cuando el componente se desmonte
      return () => {
        socket.off('newTicket');
        socket.off('newTicketAssigned');
        socket.off('ticketUpdated');
        socket.off('ticketPending');
        socket.off('ticketReassigned');
        socket.off('removePendingTicket');
      };
    }
  }, [socket, selectedTicket, memoizedSetSelectedTicket, agentId, playNewTicketSound, stopNewTicketSound]);

  // Detener la intermitencia después de un tiempo
  useEffect(() => {
    if (flashTicketId) {
      const timeout = setTimeout(() => {
        setFlashTicketId(null); // Desactiva el efecto intermitente después de un tiempo
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [flashTicketId]);

  // Función para manejar el cierre de un ticket
  const handleTicketClose = (ticketId) => {
    setAssignedTickets((prevTickets) => prevTickets.filter((ticket) => ticket._id !== ticketId));
    memoizedSetSelectedTicket(null);
  };

  return (
    <div style={dashboardStyle} className="agent-dashboard">
      <Navbar agentName={agentName} agentStatus={agentStatus} setAgentStatus={setAgentStatus} />
  
      {/* Mostrar el banner para habilitar sonido si aún no está habilitado */}
      {!userHasEnabledSound && (
        <div className="enable-sound-banner">
          <button onClick={enableSound}>Habilitar sonido para nuevos tickets</button>
        </div>
      )}
      
      <div className="left-column">
        <div className="tickets-container">
          <h3 className="ticket-list-title">Tickets Asignados</h3>
          <TicketList
            tickets={assignedTickets}
            selectTicket={memoizedSetSelectedTicket}
            flashTicketId={flashTicketId}
            selectedTicket={selectedTicket} // Asegúrate de pasar el ticket seleccionado aquí
          />
        </div>
  
        <div className="pending-tickets-list">
          <h3 className="ticket-list-title">Tickets Pendientes</h3>
          <TicketList
            tickets={pendingTickets}
            selectTicket={memoizedSetSelectedTicket}
            flashTicketId={flashTicketId}
            selectedTicket={selectedTicket} // Asegúrate de pasar el ticket seleccionado aquí también
          />
        </div>
      </div>
      
      <div className="middle-column">
        {selectedTicket ? (
          <ChatWindow
            selectedTicket={selectedTicket}
            onCloseTicket={handleTicketClose}
          />
        ) : (
          <p>No hay ticket seleccionado</p>
        )}
      </div>
      
      <div className="right-column">
        {selectedTicket && <ContactInfo selectedTicket={selectedTicket} />}
      </div>
    </div>
  );
};

export default AgentDashboard;
