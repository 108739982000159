import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal'; // Importamos Modal
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa'; // Importar íconos
import Swal from 'sweetalert2'; // Para las alertas de éxito/error
import ReactPaginate from 'react-paginate'; // Importamos ReactPaginate
import styles from './css/CategoryManagement.module.css'; // Estilos

const API_URL = process.env.REACT_APP_API_URL;

Modal.setAppElement('#root');  // Esto es importante para la accesibilidad y el posicionamiento correcto del modal

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [editCategory, setEditCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual
  const categoriesPerPage = 5; // Número de categorías por página

  // Obtener las categorías al cargar el componente
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/categories`, { withCredentials: true });
        setCategories(response.data);
      } catch (error) {
        setError('Error al cargar las categorías');
      }
    };
    fetchCategories();
  }, []);

  // Función para cambiar de página
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * categoriesPerPage;
  const currentCategories = categories.slice(offset, offset + categoriesPerPage);

  // Abrir el modal para crear una nueva categoría
  const openModal = () => {
    setModalOpen(true);
    setNewCategory({ name: '', description: '' });
    setEditCategory(null); // Limpiar categoría en edición
    setError(null);
  };

  // Cerrar el modal
  const closeModal = () => {
    setModalOpen(false);
    setEditCategory(null); // Limpiar categoría en edición
    setError(null); // Limpiar error
  };

  // Crear nueva categoría
  const handleCreateCategory = async () => {
    if (!newCategory.name.trim()) {
      setError('El nombre de la categoría es obligatorio');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/categories`, newCategory, { withCredentials: true });
      setCategories([...categories, response.data]); // Agregar la nueva categoría
      closeModal();
      Swal.fire('Categoría Creada', 'La categoría ha sido registrada con éxito.', 'success');
    } catch (error) {
      setError('Error al crear la categoría');
    }
  };

  // Editar categoría existente
  const handleEditCategory = async () => {
    if (!editCategory.name.trim()) {
      setError('El nombre de la categoría es obligatorio');
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/api/categories/${editCategory._id}`, editCategory, { withCredentials: true });
      setCategories(categories.map(cat => (cat._id === editCategory._id ? response.data : cat)));
      closeModal();
      Swal.fire('Categoría Editada', 'La categoría ha sido editada con éxito.', 'success');
    } catch (error) {
      setError('Error al editar la categoría');
    }
  };

  // Eliminar una categoría
  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${API_URL}/api/categories/${categoryId}`, { withCredentials: true });
      setCategories(categories.filter(cat => cat._id !== categoryId));
      Swal.fire('Categoría Eliminada', 'La categoría ha sido eliminada con éxito.', 'success');
    } catch (error) {
      setError('Error al eliminar la categoría');
    }
  };

  return (
    <div className={styles.categoryManagementContainer}>
      <h2>Mantenimiento de Categorías</h2>

      {/* Botón para abrir el modal de nueva categoría */}
      <button className={styles.addButton} onClick={openModal}>
        <FaPlus /> Añadir Categoría
      </button>

      <table className={styles.categoryTable}>
        <thead>
          <tr>
            <th>Nombre de la Categoría</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentCategories.map((category) => (
            <tr key={category._id}>
              <td>{category.name}</td>
              <td>{category.description}</td>
              <td>
                <div className={styles.actionButtons}>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      setEditCategory(category); // Establecer la categoría a editar
                      setModalOpen(true); // Abrir el modal
                    }}
                    disabled={category.name === 'Soporte General'} // Deshabilitar acciones en 'Soporte General'
                  >
                    <FaEdit /> Editar
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDeleteCategory(category._id)}
                    disabled={category.name === 'Soporte General'} // Deshabilitar acciones en 'Soporte General'
                  >
                    <FaTrash /> Eliminar
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Paginación */}
      <ReactPaginate
        previousLabel={'← Anterior'}
        nextLabel={'Siguiente →'}
        pageCount={Math.ceil(categories.length / categoriesPerPage)}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        activeClassName={styles.activePage}
      />

      {/* Modal para agregar/editar categoría */}
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <h3>{editCategory ? 'Editar Categoría' : 'Agregar Nueva Categoría'}</h3>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Nombre de la categoría"
          value={editCategory ? editCategory.name : newCategory.name}
          onChange={(e) =>
            editCategory
              ? setEditCategory({ ...editCategory, name: e.target.value })
              : setNewCategory({ ...newCategory, name: e.target.value })
          }
        />
        <input
          type="text"
          className={styles.inputField}
          placeholder="Descripción"
          value={editCategory ? editCategory.description : newCategory.description}
          onChange={(e) =>
            editCategory
              ? setEditCategory({ ...editCategory, description: e.target.value })
              : setNewCategory({ ...newCategory, description: e.target.value })
          }
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className={styles.modalActions}>
          <button
            className={styles.saveButton}
            onClick={editCategory ? handleEditCategory : handleCreateCategory}
          >
            {editCategory ? 'Guardar Cambios' : 'Agregar Categoría'}
          </button>
          <button className={styles.cancelButton} onClick={closeModal}>
            Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CategoryManagement;
