import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['websocket'], // Asegura que solo WebSocket se use
  reconnectionAttempts: 5,   // Intentos de reconexión
});

const VenomSessionPage = () => {
  const [qrCode, setQrCode] = useState(null);
  const [status, setStatus] = useState('disconnected');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Escuchar el evento de conexión establecida
    socket.on('connectionStatus', (connectionStatus) => {
      setStatus(connectionStatus);
    });

    // Escuchar el evento del código QR
    socket.on('qrCode', (base64Qrimg) => {
      if (!base64Qrimg.startsWith("data:image/png;base64,")) {
        setQrCode(`data:image/png;base64,${base64Qrimg}`);
      } else {
        setQrCode(base64Qrimg);
      }
    });

    // Escuchar posibles errores de conexión
    socket.on('connect_error', (error) => {
      console.error('Error en la conexión con el socket:', error);
    });

    // Cleanup on component unmount
    return () => {
      socket.off('connectionStatus');
      socket.off('qrCode');
      socket.off('connect_error');
    };
  }, []);

  const handleLogin = () => {
    setLoading(true);
    socket.emit('login');
  };

  const handleLogout = () => {
    setLoading(true);
    socket.emit('logout');
  };

  useEffect(() => {
    // Dejar de cargar cuando el estado cambie
    if (status === 'connected' || status === 'disconnected') {
      setLoading(false);
    }
  }, [status]);

  return (
    <div>
      <h1>Gestión de Sesión Venom</h1>
      <p>Estado: {status}</p>
      {/* Mostrar el código QR si está disponible */}
      {qrCode && <img src={qrCode} alt="QR Code" />}
      <button onClick={handleLogin} disabled={loading || status === 'connected'}>
        {loading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
      </button>
      <button onClick={handleLogout} disabled={loading || status === 'disconnected'}>
        {loading ? 'Cerrando sesión...' : 'Cerrar Sesión'}
      </button>
    </div>
  );
};

export default VenomSessionPage;
