import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Layout, Table, Row, Col, Card, Statistic, Modal } from 'antd';
import {
  PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList,
} from 'recharts';
import { UserOutlined, TagsOutlined, HourglassOutlined, TeamOutlined } from '@ant-design/icons';

const ResponsiveGridLayout = WidthProvider(Responsive);
const API_URL = process.env.REACT_APP_API_URL;

// Colores estáticos para estados de tickets
const STATUS_COLORS = {
  open: '#FF6384',
  closed: '#36A2EB',
  pending: '#FFCE56',
};

// Colores dinámicos para agentes y categorías
const AGENT_COLORS = ['#4CAF50', '#FF9800', '#F44336', '#2196F3', '#9C27B0', '#673AB7', '#3F51B5'];
const CATEGORY_COLORS = ['#FFC107', '#8BC34A', '#00BCD4', '#E91E63', '#9C27B0', '#607D8B', '#795548'];

function AdminDashboard() {
  const [ticketsByStatus, setTicketsByStatus] = useState([]);
  const [topAgentsByClosedTickets, setTopAgentsByClosedTickets] = useState([]);
  const [ticketsByCategory, setTicketsByCategory] = useState([]);
  const [agentStatusMetrics, setAgentStatusMetrics] = useState({ onlineAgentsList: [], offlineAgentsList: [] });
  const [totalTickets, setTotalTickets] = useState(0);
  const [pendingTickets, setPendingTickets] = useState(0);

  // Estados para controlar los modales
  const [isOnlineModalVisible, setIsOnlineModalVisible] = useState(false);
  const [isOfflineModalVisible, setIsOfflineModalVisible] = useState(false);

  const [layout, setLayout] = useState(
    JSON.parse(localStorage.getItem('dashboardLayout')) || []
  );

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/metrics`, { withCredentials: true });
        const data = response.data;

        setTicketsByStatus(data.ticketsByStatus || []);
        setTopAgentsByClosedTickets(data.topAgentsByClosedTickets || []);
        setTicketsByCategory(data.topCategories || []);
        setAgentStatusMetrics(data.agentStatusMetrics || { onlineAgentsList: [], offlineAgentsList: [] });
        setTotalTickets(data.totalTicketsReceivedToday.total || 0);
        setPendingTickets(data.ticketsByStatus?.find(ticket => ticket._id === 'pending')?.count || 0);
      } catch (error) {
        console.error('Error fetching initial metrics:', error);
      }
    };

    fetchMetrics();

    const socket = io(API_URL);

    socket.on('metricsData', (data) => {
      if (data) {
        if (data.ticketsByStatus) setTicketsByStatus(data.ticketsByStatus);
        if (data.topAgentsByClosedTickets) setTopAgentsByClosedTickets(data.topAgentsByClosedTickets);
        if (data.topCategories) setTicketsByCategory(data.topCategories);
        if (data.agentStatusMetrics) setAgentStatusMetrics(data.agentStatusMetrics);
        if (data.totalTicketsReceivedToday) setTotalTickets(data.totalTicketsReceivedToday.total || 0);
        if (data.ticketsByStatus) {
          const pending = data.ticketsByStatus.find(ticket => ticket._id === 'pending');
          setPendingTickets(pending?.count || 0);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
    localStorage.setItem('dashboardLayout', JSON.stringify(newLayout));
  };

  const showOnlineAgentsModal = () => {
    setIsOnlineModalVisible(true);
  };

  const showOfflineAgentsModal = () => {
    setIsOfflineModalVisible(true);
  };

  const handleCancel = () => {
    setIsOnlineModalVisible(false);
    setIsOfflineModalVisible(false);
  };

  return (
    <Layout style={{ padding: '24px', background: '#f0f2f5' }}>
      <h2 style={{ marginBottom: '24px' }}>Dashboard de Administrador</h2>

      {/* Tarjetas de Métricas */}
      <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total de Tickets Hoy"
              value={totalTickets}
              prefix={<TagsOutlined />}
              valueStyle={{ fontSize: '2rem', fontWeight: 'bold' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tickets En Cola"
              value={pendingTickets}
              prefix={<HourglassOutlined />}
              valueStyle={{ fontSize: '2rem', fontWeight: 'bold', color: '#FF9800' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card onClick={showOnlineAgentsModal}>
            <Statistic
              title="Agentes Online"
              value={agentStatusMetrics.onlineAgentsList.length}
              prefix={<UserOutlined />}
              valueStyle={{ fontSize: '2rem', fontWeight: 'bold', color: '#4CAF50' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card onClick={showOfflineAgentsModal}>
            <Statistic
              title="Agentes Offline"
              value={agentStatusMetrics.offlineAgentsList.length}
              prefix={<TeamOutlined />}
              valueStyle={{ fontSize: '2rem', fontWeight: 'bold', color: '#F44336' }}
            />
          </Card>
        </Col>
      </Row>

      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={100}
        isResizable
        isDraggable
        onLayoutChange={handleLayoutChange}
      >

        {/* Pie Chart: Tickets por Estado */}
        <div key="ticketsByStatus" style={{ background: '#fff', padding: '16px', borderRadius: '8px' }}>
          <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>Tickets por Estado</h3>
          {ticketsByStatus.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={ticketsByStatus.map(item => ({ name: item._id, value: item.count }))}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  label
                >
                  {ticketsByStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={STATUS_COLORS[entry._id] || '#cccccc'} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p>No hay datos de tickets por estado.</p>
          )}
        </div>

        {/* Bar Chart: Top Agentes por Tickets Cerrados */}
        <div key="topAgentsByClosedTickets" style={{ background: '#fff', padding: '16px', borderRadius: '8px' }}>
          <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>Top 5 Agentes por Tickets Cerrados</h3>
          {topAgentsByClosedTickets.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={topAgentsByClosedTickets.map((item, index) => ({
                agent: item.agent,
                closedCount: item.closedCount,
                fill: AGENT_COLORS[index % AGENT_COLORS.length]
              }))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="agent" />
                <YAxis tickCount={10} label={{ value: 'Tickets', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="closedCount">
                  {topAgentsByClosedTickets.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={AGENT_COLORS[index % AGENT_COLORS.length]} />
                  ))}
                  <LabelList 
                    dataKey="closedCount" 
                    position="insideTop" 
                    content={(props) => {
                      const { x, y, width, height, value } = props;
                      const isLargeBar = height > 20;
                      const labelY = isLargeBar ? y + 15 : y - 5;
                      const labelColor = isLargeBar ? "#fff" : "#000";
                      return (
                        <text 
                          x={x + width / 2} 
                          y={labelY} 
                          fill={labelColor} 
                          textAnchor="middle" 
                          fontSize="14"
                        >
                          {value}
                        </text>
                      );
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <p>No hay datos de tickets cerrados por agentes.</p>
          )}
        </div>

        {/* Bar Chart: Tickets por Categoría */}
        <div key="ticketsByCategory" style={{ background: '#fff', padding: '16px', borderRadius: '8px' }}>
          <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>Tickets por Categoría</h3>
          {ticketsByCategory.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={ticketsByCategory.map((item, index) => ({
                category: item.category,
                count: item.count,
                fill: CATEGORY_COLORS[index % CATEGORY_COLORS.length]
              }))}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count">
                  {ticketsByCategory.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={CATEGORY_COLORS[index % CATEGORY_COLORS.length]} />
                  ))}
                  <LabelList 
                    dataKey="count" 
                    position="insideTop" 
                    content={(props) => {
                      const { x, y, width, height, value } = props;
                      const isLargeBar = height > 20;
                      const labelY = isLargeBar ? y + 15 : y - 5;
                      const labelColor = isLargeBar ? "#fff" : "#000";
                      return (
                        <text 
                          x={x + width / 2} 
                          y={labelY} 
                          fill={labelColor} 
                          textAnchor="middle" 
                          fontSize="14"
                        >
                          {value}
                        </text>
                      );
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <p>No hay datos de tickets por categoría.</p>
          )}
        </div>

      </ResponsiveGridLayout>

      {/* Modal de Agentes Online */}
      <Modal
        title="Agentes Online"
        visible={isOnlineModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          dataSource={agentStatusMetrics.onlineAgentsList.map(agent => ({
            name: agent.name, 
            lastConnection: agent.lastConnection !== 'N/A' ? new Date(agent.lastConnection).toLocaleString() : 'N/A'  // Convertir a fecha o mostrar 'N/A'
          }))}
          columns={[
            {
              title: 'Agente',
              dataIndex: 'name',
              key: 'name',
              render: (text) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{
                    width: 40, height: 40, borderRadius: '50%',
                    backgroundColor: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    marginRight: 10, fontSize: '16px', fontWeight: 'bold'
                  }}>
                    {text[0]}  {/* Primera letra del nombre */}
                  </div>
                  {text}
                </div>
              ),
            },
            {
              title: 'Última Conexión',
              dataIndex: 'lastConnection',
              key: 'lastConnection',
            }
          ]}
          rowKey="name"
          pagination={false}
        />
      </Modal>

      {/* Modal de Agentes Offline */}
      <Modal
        title="Agentes Offline"
        visible={isOfflineModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          dataSource={agentStatusMetrics.offlineAgentsList.map(agent => ({
            name: agent.name, 
            lastConnection: agent.lastConnection !== 'N/A' ? new Date(agent.lastConnection).toLocaleString() : 'N/A'  // Convertir a fecha o mostrar 'N/A'
          }))}
          columns={[
            {
              title: 'Agente',
              dataIndex: 'name',
              key: 'name',
              render: (text) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{
                    width: 40, height: 40, borderRadius: '50%',
                    backgroundColor: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    marginRight: 10, fontSize: '16px', fontWeight: 'bold'
                  }}>
                    {text[0]}  {/* Primera letra del nombre */}
                  </div>
                  {text}
                </div>
              ),
            },
            {
              title: 'Última Conexión',
              dataIndex: 'lastConnection',
              key: 'lastConnection',
            }
          ]}
          rowKey="name"
          pagination={false}
        />
      </Modal>
    </Layout>
  );
}

export default AdminDashboard;
