import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Para hacer la solicitud al backend
import { Bar, Line, Pie } from 'react-chartjs-2';  // Usaremos Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import styles from './css/ChartCustomization.module.css';

// Registra los componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ChartCustomization = () => {
  const [chartType, setChartType] = useState('bar');  // Tipo de gráfico seleccionado
  const [dataContext, setDataContext] = useState('');  // El contexto de datos seleccionado
  const [availableContexts, setAvailableContexts] = useState({});  // Contextos disponibles
  const [chartDataPreview, setChartDataPreview] = useState(null);  // Datos del gráfico en vista previa
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);  // Estado para controlar si se muestra la vista previa
  const [chartConfigList, setChartConfigList] = useState([]);  // Lista de gráficos personalizados guardados

  // Obtener los contextos disponibles desde el backend (las métricas)
  useEffect(() => {
    const fetchDataContexts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/metrics/dashboard-metrics`, { withCredentials: true });
        setAvailableContexts(response.data);  // Guardar los contextos disponibles
      } catch (error) {
        console.error('Error al obtener los contextos de datos:', error);
      }
    };
    fetchDataContexts();
  }, []);

  // Procesar los datos según el contexto seleccionado
  const processChartData = () => {
    if (!dataContext || !availableContexts[dataContext]) return null;

    let dataForChart;

    switch (dataContext) {
      case 'ticketsByStatus':
        dataForChart = {
          labels: availableContexts.ticketsByStatus.map(item => item._id),
          datasets: [{
            label: 'Tickets por Estado',
            data: availableContexts.ticketsByStatus.map(item => item.count),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            borderColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            borderWidth: 1
          }]
        };
        break;

      case 'avgResolutionTime':
        dataForChart = {
          labels: ['Promedio de Resolución'],
          datasets: [{
            label: 'Tiempo Promedio de Resolución (ms)',
            data: [availableContexts.avgResolutionTime[0]?.avgResolutionTime || 0],
            backgroundColor: ['#36A2EB'],
            borderColor: ['#36A2EB'],
            borderWidth: 1
          }]
        };
        break;

      case 'ticketsByAgent':
        dataForChart = {
          labels: availableContexts.ticketsByAgent.assignedTickets.map(agent => agent.agent),
          datasets: [{
            label: 'Tickets Asignados por Agente',
            data: availableContexts.ticketsByAgent.assignedTickets.map(agent => agent.assignedCount),
            backgroundColor: '#36A2EB',
            borderColor: '#36A2EB',
            borderWidth: 1
          }]
        };
        break;

      case 'avgAssigned':
        dataForChart = {
          labels: ['Promedio de Tickets Asignados'],
          datasets: [{
            label: 'Promedio de Tickets Asignados',
            data: [availableContexts.avgAssigned[0]?.avgAssigned || 0],
            backgroundColor: ['#FFCE56'],
            borderColor: ['#FFCE56'],
            borderWidth: 1
          }]
        };
        break;

      case 'ticketsPerDay':
        dataForChart = {
          labels: availableContexts.ticketsPerDay.map(day => day._id),
          datasets: [{
            label: 'Tickets por Día',
            data: availableContexts.ticketsPerDay.map(day => day.count),
            backgroundColor: '#36A2EB',
            borderColor: '#36A2EB',
            borderWidth: 1
          }]
        };
        break;

      case 'ticketsByCategory':
        dataForChart = {
          labels: availableContexts.ticketsByCategory.map(category => category.category),
          datasets: [{
            label: 'Tickets por Categoría',
            data: availableContexts.ticketsByCategory.map(category => category.count),
            backgroundColor: '#FF6384',
            borderColor: '#FF6384',
            borderWidth: 1
          }]
        };
        break;

      case 'ticketsByClient':
        dataForChart = {
          labels: availableContexts.ticketsByClient.map(client => client.client),
          datasets: [{
            label: 'Tickets por Cliente',
            data: availableContexts.ticketsByClient.map(client => client.count),
            backgroundColor: '#36A2EB',
            borderColor: '#36A2EB',
            borderWidth: 1
          }]
        };
        break;

      case 'avgFirstResponseTime':
        dataForChart = {
          labels: ['Promedio de Tiempo de Primera Respuesta'],
          datasets: [{
            label: 'Tiempo Promedio de Primera Respuesta (ms)',
            data: [availableContexts.avgFirstResponseTime[0]?.avgFirstResponseTime || 0],
            backgroundColor: ['#FF6384'],
            borderColor: ['#FF6384'],
            borderWidth: 1
          }]
        };
        break;

      case 'firstContactResolutionRate':
        dataForChart = {
          labels: ['Tasa de Resolución en Primer Contacto'],
          datasets: [{
            label: 'Tasa de Resolución (%)',
            data: [availableContexts.firstContactResolutionRate[0]?.firstContactResolutionRate || 0],
            backgroundColor: ['#36A2EB'],
            borderColor: ['#36A2EB'],
            borderWidth: 1
          }]
        };
        break;

      case 'avgReassignmentTime':
        dataForChart = {
          labels: ['Promedio de Tiempo de Reasignación'],
          datasets: [{
            label: 'Tiempo Promedio de Reasignación (ms)',
            data: [availableContexts.avgReassignmentTime[0]?.avgReassignmentTime || 0],
            backgroundColor: ['#FFCE56'],
            borderColor: ['#FFCE56'],
            borderWidth: 1
          }]
        };
        break;

      default:
        dataForChart = null;
        break;
    }

    return dataForChart;
  };

  // Mostrar el gráfico en vista previa (sin guardarlo)
  const handlePreviewChart = () => {
    const chartData = processChartData();
    if (chartData) {
      setChartDataPreview(chartData);  // Actualizamos el estado para la vista previa
      setIsPreviewVisible(true);  // Hacemos visible la vista previa
    }
  };

  // Guardar la configuración del gráfico en la base de datos
  const handleSaveChart = async () => {
    if (!chartDataPreview) return;  // Asegurarse de que haya datos en la vista previa

    const chartConfig = {
      type: chartType,
      dataContext,
      data: chartDataPreview,  // Guardar la vista previa actual
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/metrics/save-chart`,
         chartConfig, { withCredentials: true });
      console.log('Gráfico guardado:', response.data);
      // Actualizamos la lista de gráficos guardados con el nuevo gráfico guardado
      setChartConfigList([...chartConfigList, response.data]);  // response.data debe contener el gráfico recién guardado
    } catch (error) {
      console.error('Error al guardar el gráfico:', error);
    }
  };

  // Eliminar un gráfico guardado
  const handleDeleteChart = async (index) => {
    try {
      const chartToDelete = chartConfigList[index];
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/metrics/delete-chart/${chartToDelete._id}`, { withCredentials: true });
      console.log('Gráfico eliminado');
      // Actualizamos la lista después de eliminar
      setChartConfigList(chartConfigList.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error al eliminar el gráfico:', error);
    }
  };

  // Renderizar el gráfico en vista previa
  const renderPreviewChart = () => {
    if (!isPreviewVisible || !chartDataPreview) return null;

    switch (chartType) {
      case 'bar':
        return <Bar data={chartDataPreview} />;
      case 'line':
        return <Line data={chartDataPreview} />;
      case 'pie':
        return <Pie data={chartDataPreview} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <h2>Personalizar Gráfico</h2>

      {/* Seleccionar tipo de gráfico */}
      <div>
        <label>Tipo de gráfico:</label>
        <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
          <option value="bar">Barra</option>
          <option value="line">Línea</option>
          <option value="pie">Pastel</option>
        </select>
      </div>

      {/* Seleccionar contexto de datos */}
      <div>
        <label>Contexto de datos:</label>
        <select value={dataContext} onChange={(e) => setDataContext(e.target.value)}>
          <option value="">Selecciona un contexto</option>
          {Object.keys(availableContexts).map((contextKey, index) => (
            <option key={index} value={contextKey}>
              {contextKey}
            </option>
          ))}
        </select>
      </div>

      {/* Botón para mostrar la vista previa */}
      <div>
        <button onClick={handlePreviewChart}>Vista Previa</button>
      </div>

      {/* Renderizar el gráfico en vista previa */}
      <div className={styles.chartContainer}>
        {renderPreviewChart()}
      </div>

      {/* Botón para guardar el gráfico */}
      {isPreviewVisible && (
        <div>
          <button onClick={handleSaveChart}>Guardar Gráfico</button>
        </div>
      )}

      {/* Lista de gráficos guardados */}
      <div className={styles.savedCharts}>
        <h3>Gráficos Guardados</h3>
        <ul>
          {chartConfigList.map((chart, index) => (
            <li key={index}>
              {chart.dataContext} - {chart.type}
              <button onClick={() => handleDeleteChart(index)}>Eliminar</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChartCustomization;
